import {
    _urlBadges,
    _urlBanks,
    _urlCallRequest,
    // _urlCustomPages,
    _urlLanguage,
    _urlMenu,
    _urlStaticTexts,
    request
} from "../api";
import {GENERAL_CONSTS } from "../constants";


export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const callRequest = (data) => {
    const requestData = {
        url: `${_urlCallRequest}`,
        method:"POST",
        data
    };
    return dispatch => {
        return request(requestData).finally(() =>{})
    }
}

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const GetMenu = () => {
    const requestData = {
        url: `${_urlMenu}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_MENU,
                    payload: data
                })
            })
    };
};
export const GetBadges = () => {
    const requestData = {
        url: `${_urlBadges}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_BADGES,
                    payload: data
                })
            })
    };
};

// export const GetCustomPages = (pageSLug) => {
//     const requestData = {
//         url: `${_urlCustomPages}/${pageSLug}`,
//         method: "GET",
//         languageFlag: true
//     };
//
//     return dispatch => {
//         return request(requestData)
//             .then((res) => {
//                 const data = res && res.data;
//                 data && dispatch({
//                     type: GENERAL_CONSTS.GET_CUSTOM_PAGES,
//                     payload: data
//                 })
//             })
//     };
// };

export const GetImages = () => {
    const requestData = {
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const GetBanks = () => {
    const requestData = {
        url: `${_urlBanks}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_BANKS,
                    payload: data
                })
            })
    };
};