import {CONST_CURRENCY} from "../constants";


export const initialState = {
    currency: [],
};

const currency = (state = initialState, action) => {
    switch (action.type) {
        case CONST_CURRENCY.GET_CURRENCY:
            return {
                ...state,
                currency: action.payload,
            };

        default:
            return state;
    }
}; export default currency