export const BUILDINGS_CONSTS = {
    GET_BUILDINGS: "GET_BUILDINGS",
    GET_BUILDING_BY_ID: "GET_BUILDING_BY_ID",
    GET_BUILDING_BEST_OFFER: "GET_BUILDING_BEST_OFFER",
    CLEARS_FILTER:"CLEARS_FILTER",
    STATE_FILTER:"STATE_FILTER",

    //Houses Constants
    GET_HOUSES: "GET_HOUSES",
};
