// import packages
import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import MouseTooltip from 'react-sticky-mouse-tooltip';
import {history} from "../../configs/history";
import {Link, useHistory} from "react-router-dom";

// Import assets
import "./buildings.scss"
import {
    BuildImage,
    BuildingsArr,
    BuildingsImage,
    BuildRightImage,
    BuildingsSelect,
    SwipeIcon
} from "../../assets/images";

// Import Components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {
    GetAbout,
    GetBuildingByIdOrSlug,
    GetBuildings,
    GetHouses,
    GetProjectByIdOrSlug
} from "../../redux/actions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import AddressWrapper from "../../components/AddressWrapper/AddressWrapper";
import {PROJECT_ID} from "../../constants/constants";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import HousesFilter from "../housesFilter/HousesFilter";


function Buildings(props) {
    const [isBuildingInfoVisible, setBuildingInfoVisible] = useState(false)
    const [buildingInfo, setBuildingInfo] = useState({})
    const [floor, setFloor] = useState({})
    const buildings = useRef(props.buildings)
    const {about, staticTexts,areas} = props
    const history = useHistory()

    const location = {
        pathname: '/filter',
        state:'buildings'
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        getBuildingsData()
        props.GetProjectByIdOrSlug(PROJECT_ID.id)

    }, [])

    async function getBuildingsData() {
        !buildings.current?.length && await props.GetBuildings().then((res) => {
            buildings.current = res
        })
        buildings?.current?.length && buildings?.current?.forEach(item => {
            props.GetBuildingByIdOrSlug(item.slug).then((building) => {
                setBuildingInfo(building);
                building?.floors?.slice(2).map(item => {
                    document.getElementById(`${building?.slug}${item?.number}`)?.addEventListener("mouseenter", onMouseEnter.bind(null, item));
                    document.getElementById(`${building?.slug}${item?.number}`)?.addEventListener("mouseleave", onMouseLeave)
                    document.getElementById(`${building?.slug}${item?.number}`)?.addEventListener("click", onClick.bind(null, building, item))
                    if (item?.inactive) {
                        document.getElementById(`${building?.slug}${item?.number}`).classList.add("inactive")
                    }
                })
            })
        })
    }

    function onMouseEnter(floor) {
        setFloor(floor);
        setBuildingInfoVisible(true);
    }

    function onMouseLeave() {
        setBuildingInfoVisible(false)
        setFloor({})
    }

    function onClick(building, floor) {
        if (!floor.inactive) {
            history.push(getUrlWithLocal(`/floor/${floor?.number}`))
        }
    }

    return <div className={'BuildingsWrapper'}>
        <div className={'TopContent'}>
            <AddressWrapper addressFirst = {staticTexts?.rout_item_buildings}/>
            <div className="ImageWrapper">
                <div className={'svg-wrapper'}>
                    <img src={BuildingsImage} alt={"building"}/>
                    <BuildingsSelect/>
                    <MouseTooltip
                        style={{zIndex: "103"}}
                        visible={isBuildingInfoVisible}
                        offsetX={15}
                        offsetY={10}
                    >
                        <div className={'tooltip-wrapper'}>
                            <div
                                className={`tooltip-left-part ${floor.inactive ? "inactive-floor" : ""} ${!floor?.housesCount ? "only-floor" : ""}`}>
                                <span className={'building-number'}>{floor.number}</span>
                                <span className={'building-text'}>{staticTexts?.mouse_info_floor}</span>
                            </div>
                            <div className={'tooltip-right-part'}>
                                <span className={'houses-count'}>
                                    {floor.number > 1 ? staticTexts?.mouse_info_is_available.replace('%', floor?.availableCount)
                                        : staticTexts?.mouse_info_office.replace('%', floor?.availableCount)}</span>
                            </div>
                        </div>
                    </MouseTooltip>
                </div>
                <div className="help-swipe load">
                    <div className="center">
                        <div className="aline">
                            <img alt="" src={SwipeIcon}/>
                        </div>
                    </div>
                </div>

                <div className={'info-block'}>
                  <div className={'about-info'}>
                      <p>
                          <h3>{staticTexts?.house_blocks_buildings_number}</h3>
                          <span>{staticTexts?.house_blocks_buildings_title}</span>
                      </p>
                      <p>
                          <h3>{staticTexts?.house_blocks_areas_number} {staticTexts?.measurement_unit_meter}<sup>2</sup></h3>
                          <span>{staticTexts?.house_blocks_areas_title}</span>
                      </p>
                      <p>
                         <h3>{staticTexts?.house_blocks_zones_number} {staticTexts?.measurement_unit_meter}<sup>2</sup></h3>
                          <span className={'some-text'}>{staticTexts?.house_blocks_zones_title}</span>
                      </p>
                      <p>
                          <h3>{staticTexts?.house_blocks_parking_place_number} +</h3>
                          <span>{staticTexts?.house_blocks_parking_place_title}</span>
                      </p>
                  </div>
                        <Link  className={'builds-info'} to={
                            {
                                pathname: getUrlWithLocal('/filter'),
                                state: 'fromBuildings'
                            }
                        }>
                            <div className={'builds'}>
                                <h3>{areas?.availableCount}</h3>
                                <span>{staticTexts?.house_available_apartments}</span>
                            <BuildingsArr/>
                            </div>
                        </Link>
                </div>
            </div>
        </div>
        <div className={'MobileInfoBlock'}>
            <div className={'info-block'}>
                <div className={'about-info'}>
                    <p>
                        <h3>{staticTexts?.house_blocks_buildings_number}</h3>
                        <span>{staticTexts?.house_blocks_buildings_title}</span>
                    </p>
                    <p>
                        <h3>{staticTexts?.house_blocks_areas_number} {staticTexts?.measurement_unit_meter}<sup>2</sup></h3>
                        <span>{staticTexts?.house_blocks_areas_title}</span>
                    </p>
                    <p>
                        <h3>{staticTexts?.house_blocks_zones_number} {staticTexts?.measurement_unit_meter}<sup>2</sup></h3>
                        <span className={'some-text'}>{staticTexts?.house_blocks_zones_title}</span>
                    </p>
                    <p>
                        <h3>{staticTexts?.house_blocks_parking_place_number} +</h3>
                        <span>{staticTexts?.house_blocks_parking_place_title}</span>
                    </p>
                </div>
                <div className={'builds-info'}>
                    <h3>{areas?.availableCount}</h3>
                    <span>{staticTexts?.house_available_apartments}</span>
                    <BuildingsArr/>
                </div>
            </div>
        </div>
        <div className={'DescriptionContent'}>
            <div className={'background-block'}></div>
            <div className={'LeftSide'}>
                <div className={'text_wrapper'}>
                    <div className={'LeftSideTitle'}>{about?.blocks[2]?.title}</div>
                    <div className={'buildings-text'} dangerouslySetInnerHTML={{__html:  about?.blocks[2]?.description}}>
                        </div>
                </div>
                <div className={'img_wrapper'}>
                    <div className={'leftImg-block'}>
                        <img className={'buildings-images'} src={generateFileMediaUrl(about?.blocks[2]?.mediaMain?.path)} alt=""/>
                    </div>
                </div>
            </div>
            <div className={'RightSide'}>
                <div className={'text-wrapper'}>
                    <div className={'RightSideTitle'}>{about?.blocks[3]?.title}</div>
                    <div className={'buildings-text'} dangerouslySetInnerHTML={{__html:  about?.blocks[3]?.description}}>
                    </div>
                </div>
                <div className={'rightImg-wrapper'}>
                    <div className={'rightImg-block'}>
                        <img className={'buildings-images'} src={generateFileMediaUrl(about?.blocks[3]?.mediaMain?.path)} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'buildings',
    'contact',
    'about',
    'areas',
    'projects',
    'projectById',
]);

const mapDispatchToProps = {
    GetBuildingByIdOrSlug,
    GetBuildings,
    GetAbout,
    GetHouses,
    GetProjectByIdOrSlug,
};

export default connect(mapStateToProps, mapDispatchToProps)(Buildings);
