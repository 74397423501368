import React from "react";
import './clock.scss'
import {Centre, ClockIc} from "../../../assets/images";


function Clock ({hourRatio, minuteRatio, secondRatio,city,staticTexts}) {

    return (
        <div className={'clock'}>
            <div className={'hand hour'} style={{transform: `translate(-50%) rotate(${hourRatio * 360}deg)`}}></div>
            <div className={'hand minute'} style={{transform: `translate(-50%) rotate(${minuteRatio * 360}deg)`}}></div>
            <div className={'hand second'} style={{transform: `translate(-50%) rotate(${secondRatio * 360}deg)`}}></div>
            <ClockIc/>
                <div className={'clock-face'}>
                    <Centre/>
                </div>
            <div className={'city'}>
                {staticTexts[city]}
            </div>
        </div>
    )
}export default Clock