// import packages
import React from "react";
import {connect} from "react-redux";

// Import assets
import "./homepage.scss"

// Import Components
import MainSlider from "./slider/MainSlider";
import About from "./about/About";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import ProfitableOffer from "./profitableOffer/ProfitableOffer";



function Homepage() {
    return <>
            <div className={'Homepage'}>
               <div className={'sliderImg-Wrapper'}>
                   <div className={'img-Wrapper'}>
                       <MainSlider/>
                   </div>
               </div>
                <div className={'bottom-block'}>
                    <About/>
                    <ProfitableOffer/>
                </div>
            </div>
    </>
}

const mapStateToProps = (state) => getPropsFromState(state, [
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
