// Import packages
import React, {useEffect, useState} from "react";

// Import styles
import "./progress-bar.scss";

export function ProgressBar(props) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if(props.sliderCircleEnded ){
            setAnimate(false);
        }else{
            setAnimate(true);
        }
    }, [props.sliderCircleEnded ]);

    return <div className="progress-wrapper">
        <span
            className={'slide-index'}>{props.slideIndex < 9 ? 0 : ''}{`${props.slideIndex + 1}`}</span>
        <div className={'progress-bar'}>
            <span className={`stroke ${animate ? 'animate' : ''}`}/>
        </div>
        <span
            className={'slide-index'}>{`${props.slideIndex < 9 ? 0 : ''}${props.sliderLenght}`}</span>
    </div>
}