import React, {useRef} from 'react'
import './ProfitableOffer.scss'
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {GetHouses,GetHouseBestOffers} from "../../../redux/actions";
import Slider from "react-slick";
import {SliderButton} from "../../../components/ui-elements/buttons/Buttons";
import SlidersCard from "../../../components/cards/SlidersCard";



function ProfitableOffer(props) {
    const {staticTexts,bestOffers} = props
    let sliderRef = useRef(null);

    const settings = {
        dots: false,
        arrows: false,
        infinite: window.innerWidth < 700,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: window.innerWidth < 700,
        centerPadding: '20px 0 0',
        ref: slider => (sliderRef = slider),
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    return <div className={'ProfitableOffer'}>
        <div className={'title-block'}>
            <h1 className={'title'}>{staticTexts?.homepage_best_offer_title}</h1>
            <div className={'description'}>{staticTexts?.homepage_best_offer_description}</div>
        </div>
        <div className={'offer-slider-block'}>
            {bestOffers?.length &&
                <SliderButton
                    className={'desktop-arrows'}
                    cbPrev={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        sliderRef.slickPrev()
                    }}
                    cbNext={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        sliderRef.slickNext()
                    }}
                />}
            {bestOffers?.length && <Slider{...settings}>
                {bestOffers?.map(item => {
                    return <div key={item?.id} className={'CardWrapper'}>
                        <SlidersCard areas={item}/>
                    </div>
                })}
            </Slider>}
        </div>
    </div>

}
const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'areas',
    'bestOffers',
]);

const mapDispatchToProps = {
    GetHouses,
    GetHouseBestOffers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfitableOffer);
