// Import packages
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { history } from "../configs/history";

// Import assets
import '../assets/styles/layout.scss'

// Import components
import Header from "../components/Header";
import Footer from "../components/Footer";

// Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import {
    GetLanguages,
    GetStaticTexts,
    GetProject,
    GetAbout,
    GetMainSlider,
    GetContact,
    GetBuildings,
    GetHouses,
    GetCurrency, GetHouseBestOffers, FilterState, GetBadges
} from "../redux/actions";
import MetaTags from "../components/MetaTags";
import LeftBlock from "../components/LeftBlock";
import {Call, FilterClose, Mobil_icon, SoundOfIcon, SoundOnIcon} from "../assets/images";
import CallModel from "../components/modal/callModel/CallModel";
import sessionStorage from "redux-persist/es/storage/session";
import {BUILDING_TYPE, MAX_AREA, MAX_PRICE, MIN_AREA, MIN_PRICE} from "../constants/acceptedConsts";
import HousesFilter from "./housesFilter/HousesFilter";

function Layout (props) {
    const filtersInitialValues = {
        buildingSlug: null,
        floors: undefined,
        roomsCount: [],
        minArea: MIN_AREA,
        maxArea: MAX_AREA,
        minPrice: MIN_PRICE,
        maxPrice: MAX_PRICE,
        types:BUILDING_TYPE.Apartments,
        statuses: [],
        sort: 'price',
    }
    const [filters, setFilters] = useState(filtersInitialValues)
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
    const [block, setBlock] = useState(false)
    const [soundOn, setSoundOn] = useState(false)
    const [callModelOpen,setCallModelOpen] = useState(false)
    let val = JSON.parse(window.sessionStorage.getItem('loading')) === null ? true : JSON.parse(window.sessionStorage.getItem('loading'))
    const [loading,setLoading] = useState(
        val
    )
    const WaveVideo = "https://api.retroshin.am/admin/files/retroshin.mp4"

   function videoState() {
        const value = false
        setLoading(value)
        sessionStorage.setItem('loading',value)
    }

    useEffect(() => {
        props.GetLanguages()
        props.GetStaticTexts()
        props.GetProject()
        props.GetCurrency()
        props.GetHouses()
        props.GetHouseBestOffers()
        props.GetBadges()
        props.FilterState(filtersInitialValues)
        props.GetMainSlider().then(() => {
            props.GetAbout()
            props.GetContact()
            props.GetBuildings()
        })
    }, [ props.selectedLanguage?.id]);


    const toggleMobileMenu = () => {
        setMobileMenuIsOpen(!mobileMenuIsOpen)
    };

    const toggleMobileFilter = () => {

        setBlock(!block)
    };
    const toggleCallBtn = () => {
        setCallModelOpen(!callModelOpen)
    };

    const toggleSoundOn =(e)=> {
        let video = document.querySelector('video')
            e.preventDefault();
            video.muted = !video.muted;
            setSoundOn(!soundOn)
    }

    const { children ,staticTexts} = props;
    return <div className={`LayoutWrapper ${mobileMenuIsOpen ? "mobileMenuOpen" : ""}`}>
        <div className={`video-wrapper ${loading ?'show' : ''}`}>
            <video autoPlay
                   playsInline
                   muted
                   onError={() => setLoading(false)}
                   onEnded={videoState}
                   src={WaveVideo}
                   className={'video'}
            >
            </video>
            <button className={'sound-on'} onClick={toggleSoundOn}>
                {
                    soundOn ?
                        <SoundOnIcon/> :
                        <SoundOfIcon/>
                }
            </button>
        </div>
        <MetaTags/>
        <Header mobileMenuIsOpen={mobileMenuIsOpen}
                toggleMobileMenu={toggleMobileMenu}/>
        <CallModel  toggleCallBtn={toggleCallBtn}
                    callModelOpen={callModelOpen}/>
        {
            window.innerWidth < 1001 ?
                <div className={`menu-dropdown ${block ? "open" : ""}`}>
                    <span className={'close-icon'}  onClick={toggleMobileFilter}><FilterClose/></span>
                    <LeftBlock toggleMobileFilter={toggleMobileFilter}
                               filtersInitialValues={filtersInitialValues}
                               setFilters={setFilters}
                               filters={filters} />
                </div> :
                <div className={'left-block-wrapper'}>
                    <LeftBlock  filtersInitialValues={filtersInitialValues}
                                setFilters={setFilters}
                                filters={filters}/>
                </div>
        }
        <div className={`mobile-wrapper ${history.location.pathname.includes('home')  || history.location.pathname.includes('address') || history.location.pathname.includes('privacy-policy')? 'top': ''}`}>
            <div className={'mobil-block'} onClick={toggleMobileFilter}>
                <Mobil_icon/>
                <span>{staticTexts?.filter_title}</span>
            </div>
        </div>
        <div className={`MainContent`}>
            {children}
        </div>
        <button className='callBtn' onClick={toggleCallBtn}>
            <Call title=''/>
        </button>
        <Footer/>

         <div className={'layoutDisable'}>
                <HousesFilter  filtersInitialValues={filtersInitialValues}
                               setFilters={setFilters}
                               filtersList={filters}/>
            </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'selectedLanguage',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetStaticTexts,
    GetAbout,
    GetMainSlider,
    GetContact,
    GetBuildings,
    GetProject,
    GetCurrency,
    GetHouseBestOffers,
    GetHouses,
    FilterState,
    GetBadges,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
