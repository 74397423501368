// import packages
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Dropdown, Breadcrumb, Menu, Radio} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

// Import assets
import "./houses-filter.scss"
import {
    LocationIcon,
    ParkingIcon,
    SortArr,
} from "../../assets/images";

// Import Components
import HouseCard from "../../components/cards/HouseCard";
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetHouses, ClearHouses, ClearFilter, GetBadges,FilterState} from "../../redux/actions";
import {BUILDINGS_KEYS} from "../../constants/constants";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";

const filtersInitialValues = {
    buildingSlug: null,
    floors: undefined,
    roomsCount: 0,
    minArea: '',
    maxArea: '',
    minPrice: '',
    maxPrice: '',
    statuses: [],
    sort: 'price',
}



function HousesFilter(props) {
    const {areas, badges,clear,filterState} = props
    const location = useLocation()
    const { staticTexts} = props
    const {
        list,
        hasMore,
        buildingSlug,
    } = areas || {}

    const [filters, setFilters] = useState(filterState)
    useEffect(() => {
        if (location?.state) {
            getStatusValue('600ab6e5e503271f1c8ebcd0')
        }
        props.GetBadges()
    }, [])

    // useEffect(() => {
    //     if(filtersList){
    //         setFilters(filtersList)
    //     }
    // },[filtersList])

    function loadMoreItems() {
        hasMore && props.GetHouses({
            ...filterState,
            offset: list.length,
            reset: false
        })
    }

    // useEffect(() => {
    //         if(!clear){
    //             changeFilter('clear', null)
    //         }
    // },[clear])

    function changeFilter(name, value) {
        let newFilters = {
            ...filterState,
            [name]: value
        };
        if (name === "minPrice" || name === "maxPrice") {
            newFilters[name] = value.replace(/\D/g, '')
        }
        if (name === "clear") {
            newFilters = filtersInitialValues
        }
        compareObjects(newFilters)
        setFilters(newFilters);
        props.GetHouses(newFilters)
        props.FilterState(newFilters)
    }

    function compareObjects(newArray) {
        let visible = false
        Object.keys(filtersInitialValues).forEach(key => {
            if (Array.isArray(newArray[key])) {
                if (newArray[key]?.length !== filtersInitialValues[key]?.length) {
                    visible = true
                }
            } else {
                if (newArray[key] !== filtersInitialValues[key]) {
                    visible = true
                }
            }
        })
        props.ClearFilter(visible)
    }

    function getSortValue(name, value) {
        props.ClearFilter(true)
        changeFilter(name, value)
    }

    function getStatusValue(name) {
        props.ClearFilter(true)
        const statuses = !filters?.statuses?.includes(name) ? filters?.statuses.concat(name) : filters?.statuses?.filter(item => item !== name)
        changeFilter("statuses", statuses)
    }

    const sortOptions = [
        {name: staticTexts?.sort_by_price_increase, id: 'price'},
        {name: staticTexts?.sort_by_price_reduction, id: '-price'},
        {name: staticTexts?.sort_by_area_increase, id: 'area'},
        {name: staticTexts?.sort_by_area_reduction, id: '-area'},
        {name: staticTexts?.sort_by_rooms_count_increase, id: 'roomsCount'},
        {name: staticTexts?.sort_by_rooms_count_reduction, id: '-roomsCount'},
    ]

    const {floor} = location?.state || {}

    const menuPdf = !!badges?.length && (
        <Menu className="statuses-dropdown">
            {badges.filter(badge => badge?.id !== "600ab6e5e503271f1c8ebcd0")?.map((badge) => {
                return <Menu.Item key={badge.id}>
                    <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                        getStatusValue(badge?.id)
                    }
                    }>
                        <Radio className={'house-filter-checkbox'} checked={filters?.statuses.includes(badge?.id)}>
                        {badge?.id === "600ab6e5e503271f1c8ebcd0" ? `${badge?.title} (${areas?.availableCount && areas?.availableCount})`
                            : badge?.id === "6012c054b88dc494783275c5" ? `${badge?.title} (${areas?.reservedCount && areas?.reservedCount})` : badge?.title}
                    </Radio>
                    </div>
                </Menu.Item>
            })
            }
        </Menu>
    );

    const avaiableBadge = badges?.find(badge => badge?.id === "600ab6e5e503271f1c8ebcd0")

    return <div className={'HousesFilters-Wrapper'}>
        <div className={'AddressWrapper'}>
            <div className={'address-left-wrapper'}>
                <Breadcrumb>
                    <Breadcrumb.Item><Link
                        to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                    {buildingSlug && <Breadcrumb.Item><Link
                        to={getUrlWithLocal('/buildings')}>{`${staticTexts?.rout_item_building} ${BUILDINGS_KEYS[buildingSlug]}`}</Link></Breadcrumb.Item>}
                    {floor && <Breadcrumb.Item>
                        <Link to={getUrlWithLocal(`/floor/${floor}`)}>
                            {`${staticTexts?.rout_item_title_floor} ${floor}`}
                        </Link>
                    </Breadcrumb.Item>}
                    <Breadcrumb.Item>{staticTexts?.rout_item_filter_title}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className={'address-right-wrapper'}>
                <Link to={getUrlWithLocal('/floor/-1')} className={'item-card'}>
                    <div className={'icon-wrapper'}>
                        <ParkingIcon />
                    </div>
                </Link>
                <Link to={getUrlWithLocal('/address')} className={'item-card'}>
                    <div className={'icon-wrapper'}>
                        <LocationIcon/>
                    </div>
                </Link>
            </div>
        </div>
        <div className={'filters'}>
            <div className={'filter-top'}>
                <div className={"filters-sort-part"}>
                    <div className={'status-sort'}>
                        <Dropdown overlay={menuPdf}
                                  trigger={['click']}
                                  overlayClassName={'statuses-wrapper'}
                                  placement="bottomLeft"
                                  size={'small'}>
                            <div className={"dropdown-btn"}>
                                <div>
                                    <Radio className={'house-filter-checkbox'} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation()
                                        getStatusValue(avaiableBadge?.id)
                                    }}
                                           checked={filterState?.statuses.includes(avaiableBadge?.id) && clear}
                                    />{avaiableBadge && `${avaiableBadge?.title} (${areas?.availableCount && areas?.availableCount})`}
                                </div>
                                <SortArr className={'status-sort-icon'}/>
                            </div>
                        </Dropdown>
                    </div>
                    <div className={'sort'}>
                        <InputGroup
                            showSearch={false}
                            inputType={'select'}
                            value={filterState?.sort}
                            name={'sort'}
                            placeholder={staticTexts?.filter_sort_placeholder}
                            onChange={getSortValue}
                            options={sortOptions}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="houses-wrapper">
            {areas?.count === 0 ?
                <div className={'not-found'}>
                    {staticTexts?.filter_not_found}
                </div> :
                <InfiniteScroll
                    hasMore={hasMore}
                    loadMore={loadMoreItems}
                    className={'houses-list'}
                    pageStart={0}
                    threshold={500}
                    initialLoad={false}>
                    {!!list?.length && list.map(house => {
                        return <div className={'cards-wrapper'}>
                            <HouseCard house={house} key={house?.id}/>
                        </div>
                    })}
                </InfiniteScroll>
            }
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'areas',
    'badges',
    'buildings',
    'clear',
    'filterState',
]);

const mapDispatchToProps = {
    GetHouses,
    ClearHouses,
    ClearFilter,
    GetBadges,
    FilterState,
};

export default connect(mapStateToProps, mapDispatchToProps)(HousesFilter);
