// import packages
import React, {Component} from "react";
import Slider from "react-slick";
import {connect} from "react-redux";

//Import styles
import "./mainSlider.scss";
import { ArrowLeft, ArrowRight} from "../../../assets/images";

// Import utils
import {generateSliderMediaUrl} from "../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {ProgressBar} from "../../../components/progress-bar/Progress-Bar";

class MainSlider extends Component {
    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            loadedImg: false,
            sliderCircleEnded: false,
        };
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    getSliderIndex = (index) => {
        this.setState({
            slideIndex: index,
        });
        this.slider.slickGoTo(index);
    };

    nextSlide = (index) => {
        const {mainSlider} = this.props;
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (mainSlider?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: 0,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        const {mainSlider} = this.props;
        this.setState({
            slideIndex: index === 0 ? mainSlider?.length - 1 : index - 1,
        });
        this.slider.slickPrev();
    };

    handleImageLoaded(index) {
        if (!this.state.loadedImg) {
            this.setState({loadedImg: true});
        }
    }

    afterChangeSlideIndex = (slideIndex) => {
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_, slideIndex) => {
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    render() {
        const {slideIndex,sliderCircleEnded} = this.state;
        const {mainSlider} = this.props;
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            autoplaySpeed: 5000,
            speed: 400,
            autoplay: true,
            pauseOnHover: false,
            lazyLoad: 'progressive',
            swipeToSlide: true,
            swipe: true,
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            fade: true,
        };

        return !!mainSlider && !!mainSlider.length && <div className="image-wrapper">
            <div className={'main-slider-items'}>
                <div className={'main-slider-inner-part'}>
                    <Slider {...settings}>
                        {mainSlider?.length && mainSlider?.map((item, index) => {
                            let mobileImg = item?.mediaMain?.path?.split('.')
                            mobileImg?.splice(mobileImg.length - 1, 0, "-sm")
                            mobileImg?.splice(mobileImg.length - 1, 0, ".")


                            const orientation = window.screen?.orientation?.type;
                            return <div key={item.id} className={`slider-item`} ref={this.img}>
                                {
                                   ( window.innerWidth > 800 && orientation === "landscape-primary") ?
                                        <img src={generateSliderMediaUrl(item?.mediaMain?.path)} alt={""}
                                             onLoad={() => this.handleImageLoaded(index)}/>
                                        :
                                        <img src={generateSliderMediaUrl((mobileImg.join('')))}
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = generateSliderMediaUrl(item?.mediaMain?.path)
                                             }}
                                             alt={''}/>}
                            </div>
                        })}
                    </Slider>
                </div>
                {
                    !!mainSlider && !!mainSlider.length &&
                    <div className={'slider-bottom-content'}>
                        {mainSlider?.length !== 1 && <div className={'slider-arrows'}>
                            <div className={'slider-btn prev-btn'}
                                 onClick={() => this.prevSlide(slideIndex)}>
                                <ArrowLeft/>
                            </div>
                            <ProgressBar slideIndex={slideIndex}
                                         sliderCircleEnded={sliderCircleEnded}
                                         sliderLenght={mainSlider?.length}/>
                            <div className={'slider-btn next-btn'}
                                 onClick={() => this.nextSlide(slideIndex)}>
                                <ArrowRight/>
                            </div>
                        </div>}
                    </div>
                }
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'mainSlider',
        'contact',
    ])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainSlider);
