import {_urlRateList, request} from "../api";
import {CONST_CURRENCY} from "../constants";


export const GetCurrency = () => {
    const requestData = {
        url: _urlRateList,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: CONST_CURRENCY.GET_CURRENCY,
                    payload: data
                })
            })
    };
};