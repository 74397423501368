import { _urlProject, request} from "../api";
import {PROJECT_CONST} from "../constants";


export const GetProject = () => {
    const requestData = {
        url: `${_urlProject}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: PROJECT_CONST.GET_PROJECT,
                    payload: data
                })
                return data
            })
    };
};


export const GetProjectByIdOrSlug = (id) => {
    const requestData = {
        url: `${_urlProject}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type:PROJECT_CONST.GET_PROJECT_BY_ID,
                    payload: data
                })
                return data
            })
    };
};

