// import packages
import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import MouseTooltip from 'react-sticky-mouse-tooltip';
import {Popover} from 'antd';
import {history} from "../../configs/history";
import {useParams} from 'react-router-dom';

// Import assets
import "./floor-details.scss"
import {Share_Icon} from "../../assets/images";

// Import Components
import MetaTags from "../../components/MetaTags";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetFloorById, GetBuildingByIdOrSlug,GetBadges} from "../../redux/actions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {
    floorsImages,
    floorsSelect,
    HOUSE_BADGES,
    PARKING_COUNTS, RETROSHIN_LINK,
    SOLD_IMAGES
} from "../../constants/constants";
import {ACCEPT_BADGE_TYPES} from "../../constants/acceptedConsts";
import AddressWrapper from "../../components/AddressWrapper/AddressWrapper";
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share";


function FloorDetails(props) {
    const componentRef = useRef();
    const [isHouseInfoVisible, setHouseInfoVisible] = useState(false)
    const [housesInfo, setHousesInfo] = useState({})
    const [floor, setFloor] = useState({})
    const params = useParams()
    const {buildingById, badges, staticTexts, selectedLanguage,areas} = props
    let floorId = ''

    useEffect(async () => {
        props.GetBadges()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const buildingData = await props.GetBuildingByIdOrSlug('A1')
        const floorId = buildingData?.floors?.length && buildingData?.floors?.find(item => item.number === +params.num)?.id
        const val = buildingData?.floors?.length && buildingData?.floors?.find(item => item.number === +params.num)
        await props.GetFloorById(floorId).then((floor) => {
            setFloor(floor);
        }).catch(() => {
        })
    }, [])
    useEffect(async () => {
        const buildingData = await props.GetBuildingByIdOrSlug('A1')
        let floorId =buildingData?.floors?.length
            && buildingData?.floors?.find(item => item.number === +params.num)?.id

       props.GetFloorById(floorId).then((floor) => {
            setFloor(floor);
        })
    }, [params.num])

    useEffect(() => {
        const items =  floor?.areas ;
        items && items?.length && items?.map(item => {
            if(floor?.number){
                if (document.getElementById(item?.areaNumber)) {
                    document.getElementById(item?.areaNumber)?.addEventListener("mouseenter", onMouseEnter.bind(null, item));
                    document.getElementById(item?.areaNumber)?.addEventListener("mouseleave", onMouseLeave.bind(null, item))
                    document.getElementById(item?.areaNumber)?.addEventListener("click", onClick.bind(null, item))
                    document.getElementById(item?.areaNumber).style.stroke = item?.badge?.color;
                    document.getElementById(item?.areaNumber).style.fill = item?.badge?.color;

                    if (floor?.number) {
                        if (item?.badge?.id === ACCEPT_BADGE_TYPES?.FOR_SALE) {
                            document.getElementById(item?.areaNumber).style.stroke = item?.badge?.color;
                            document.getElementById(item?.areaNumber).style.fill = item?.badge?.color;
                        }
                    }
                }
            }else{
                if (document.getElementById(item?.position)) {
                    document.getElementById(item?.position)?.addEventListener("mouseenter", onMouseEnter.bind(null, item));
                    document.getElementById(item?.position)?.addEventListener("mouseleave", onMouseLeave.bind(null, item))
                    document.getElementById(item?.position)?.addEventListener("click", onClick.bind(null, item))
                    document.getElementById(item?.position).style.stroke = item?.badge?.color;
                    document.getElementById(item?.position).style.fill = item?.badge?.color;

                    if (floor?.number < 0) {
                        if (item?.badge?.id === ACCEPT_BADGE_TYPES?.FOR_SALE) {
                            document.getElementById(item?.position).style.stroke = item?.badge?.color;
                            document.getElementById(item?.position).style.fill = item?.badge?.color;
                        }
                    }
                }
            }
        })

    }, [floor.id])

    function onMouseEnter(house) {
        setHouseInfoVisible(true);
        setHousesInfo(house);
    }

    function onMouseLeave(house) {
        setHouseInfoVisible(false)
    }

    function onClickFloor(floor) {
        if(floor.number !== 1){
            floorId = floor.id
            if (floor.number === -1) {
                floorId = PARKING_COUNTS['-1']
            }
            if (floor.number === -2) {
                floorId = PARKING_COUNTS['-2']
            }
            !floor?.inactive && floor?.id && props.GetFloorById(floorId).then((data) => {
                if (floor?.number > 0) {
                    history.push(getUrlWithLocal(`/floor/${data?.number}`))
                } else {
                    history.push(getUrlWithLocal(`/floor/${data?.number}`))
                }
                setFloor(data);
            })
        }
    }

    function onClick(house) {
        if (params?.num < 0) {
            history.push(getUrlWithLocal(`/floor/${params?.num}/place/${house?.id}`))

        } else {
            history.push(getUrlWithLocal(`/floor/${params?.num}/place/${house?.id}`))
        }
    }

    const Svg = floorsSelect[`A1${params?.num}`]

    const content = (
        <div className={'social-media'}>
            <FacebookShareButton url={RETROSHIN_LINK.url} quote={"Hello World"}>
                <p><FacebookIcon size={18} round={true}/> Facebook</p>
            </FacebookShareButton>
            <TwitterShareButton url={RETROSHIN_LINK.url} title={'Retroshin'} >
                <p><TwitterIcon size={18} round={true}/> Twitter</p>
            </TwitterShareButton>
            <LinkedinShareButton url={RETROSHIN_LINK.url} title={'Retroshin'} description={'Hello'}>
                <p><LinkedinIcon size={18} round={true}/> Linkedin</p>
            </LinkedinShareButton>
        </div>
    );

    return <div className={'FloorWrapper'}>
        <MetaTags
            title={`${staticTexts?.rout_item_floor} ${floor?.number}`}/>
        <div className={'ContentWrapper'}>
            <AddressWrapper addressFirst ={`${staticTexts?.rout_item_floor} ${floor?.number ?  floor?.number : ' '}`}/>
            <div className={'DistributeWrapper'}>
                <div className={'share-block'}>
                    <div className={'share'}>
                        <Popover content={content} trigger="click" placement="bottom">
                                <Share_Icon/>
                                <span>{staticTexts?.share_title}</span>
                        </Popover>

                    </div>
                </div>
            </div>
            <div className={"FloorMainContent"}>
                <div className={'LeftSide'} id="result-table">
                    <div className={'floors-main-wrapper'}>
                        <div className={'floors-content'}>
                            <div
                                className={'selected-floor'}>{`${staticTexts?.rout_item_floor} ${floor?.number ? floor?.number : ''}`}    {staticTexts?.house_out_of} 16</div>
                            <div className={'floors-list'}>
                                {buildingById && !!buildingById?.floors?.length && buildingById?.floors?.slice(2).map(item => {
                                    return <div onClick={() => onClickFloor(item)}
                                                key={item?.id}
                                                className={`list-item ${item.number === floor?.number ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}</div>
                                })}
                            </div>
                            <div className={'parking-wrapper'}>
                                <div className={'parking-title'}>{staticTexts?.rout_item_title_parking}</div>
                                <div className="parking-floors-list">
                                    {buildingById && !!buildingById?.floors?.length && buildingById?.floors?.slice(0, 2).reverse().map(item => {
                                        return <div onClick={() => onClickFloor(item)}
                                                    key={item?.id}
                                                    className={`list-item ${item.number === floor?.number ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}</div>
                                    })}
                                </div>
                            </div>
                            <div className={'freedom-buildings'}>{staticTexts?.house_available_apartments} {areas?.availableCount}</div>
                        </div>
                    </div>
                    <div className={'badges_block'}>
                        <div className={'badges-wrapper'}>
                            {badges && !!badges?.length && badges.map(item => {
                                return <div className={'badge-item'} key={item?.id}>
                                    <div className={'badge-color'} style={{background: item.color}}/>
                                    <div className={'color-text'}>{item?.title}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className={'RightSide'} >
                    <div className={`ImageWrapper ${params?.num < 0 ? "ParkingImageWrapper" : ""}`} ref={componentRef}>
                        <img src={floorsImages[`A1${params?.num}`]} alt={"building"}/>
                        {params?.num < 0 ? !floor?.areas?.find(item => item?.badge?.id !== HOUSE_BADGES.sold.key)
                            : !!floor?.areas?.length && !floor?.areas?.find(item => item?.badge?.id !== HOUSE_BADGES.sold.key) &&
                            <img className="sold-item" src={SOLD_IMAGES?.[selectedLanguage?.code]} alt=""/>
                        }
                        {floorsImages[`A1${params?.num}`] && <Svg/>}
                        <MouseTooltip
                            style={{zIndex: "103"}}
                            visible={isHouseInfoVisible}
                            offsetX={-115}
                            offsetY={30}
                        >
                            <div className={'tooltip-wrapper'}>
                                <div className={`tooltip-left-part ${params?.num < 0 ? "without-right-part" : ""}`}
                                     style={{background: housesInfo?.badge?.color}}>
                                    <span
                                        className={'building-number'}>N {params?.num < 0 ? housesInfo?.areaNumber: housesInfo.areaNumber}</span>
                                </div>
                                {params?.num > 0 && <div className={'tooltip-right-part'}>
                                    <span
                                        className={'floors-count'}>{housesInfo?.roomsCount} {staticTexts?.mouse_info_room}</span>
                                    <span
                                        className={'houses-count'}>{`${housesInfo?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup></span>
                                </div>
                                }
                            </div>
                        </MouseTooltip>
                    </div>
                </div>
                <div className={'mobile-badges-wrapper'}>
                    <div className={'badges-wrapper'}>
                        {badges && !!badges?.length && badges.map(item => {
                            return <div className={'badge-item'} key={item?.id}>
                                <div className={'badge-color'} style={{background: item.color}}/>
                                <div className={'color-text'}>{item?.title}</div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'about',
    'buildings',
    'buildingById',
    'badges',
    'areas',
    'selectedLanguage',
]);

const mapDispatchToProps = {
    GetFloorById,
    GetBuildingByIdOrSlug,
    GetBadges,
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorDetails);
