import { PROJECT_CONST} from "../constants";


export const initialState = {
    projects: [],
    projectById: null,
};

const project = (state = initialState, action) => {

    switch ( action.type ) {
        case PROJECT_CONST.GET_PROJECT:
            return {
                ...state,
                projects: action.payload,
            };
        case PROJECT_CONST.GET_PROJECT_BY_ID:
            return {
                ...state,
                projectById: action.payload,
            };
        default:
            return state;
    }
};export default project