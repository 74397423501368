import React, {useEffect, useState} from "react";
import Clock from "./ui-elements/clock/Clock";
import './ui-elements/clock/clock.scss'
import {getPropsFromState} from "../redux/mapStateToProps";
import {connect} from "react-redux";


function ClockBlocks(props){
    const{staticTexts} = props
     const TIME_CITY = {
         [`time_Yerevan`]: 4,
         [`time_Moscow`]: 3,
         [`time_Paris`]: 2,
         [`time_Los_Angeles`]: 28,
    }
    const [times ,setTimes] = useState([])


    useEffect(() => {
        setInterval(() => {
            setTime()
        },1000)
    },[])

    function setTime() {
        const currentData = new Date();
        let timeValue = []
        Object.entries(TIME_CITY)?.map(([key,value],) => {
            let time = {}
            time.secondRatio = currentData.getUTCSeconds() /60;
            time.minuteRatio = (time.secondRatio + currentData.getUTCMinutes()) / 60;
            time.hourRatio = (time.minuteRatio + currentData.getUTCHours() + value)  / 12
            time.key = key
            timeValue.push(time)
        })

        setTimes(timeValue)
    }

    //
    return <div className={'clocks-block'}>
        {times?.map((item) => {
            // console.log(item, 'TIME_CITY[key]');
                return <Clock key={item.key}
                              staticTexts={staticTexts}
                              secondRatio={item?.secondRatio}
                              minuteRatio={item?.minuteRatio}
                              hourRatio={item?.hourRatio}
                              city={item.key}/>
        })}
    </div>

}
const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ClockBlocks);