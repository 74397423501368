import {Modal, TimePicker,Checkbox} from "antd";
import './callModel.scss'
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {callRequest} from "../../../redux/actions";
import {connect} from "react-redux";
import React, {useState} from "react";
import {InputGroup} from "../../ui-elements/input-group/inputGroup";
import {CloseModalIcon} from "../../../assets/images";


function CallModel(props) {
    const {toggleCallBtn,callModelOpen,staticTexts} = props
    const [showTime,setShowTime] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmBtnDisable, setConfirmBtnDisable] = useState(false);
    const [inputValue,setInputValue] = useState({
        fullName: "",
        phoneNumber: "",
        time:"",
    })
    const [errors,setErrors] = useState({
        fullName: "",
        phoneNumber: "",
    })
    function validation() {
        let errors = {}
        let result = true
        if(!inputValue.fullName){
            errors.fullName =true;
            result = false
        }
        if(inputValue.phoneNumber.length < 9){
            errors.phoneNumber =true;
            result = false
        }
        setErrors(errors);
        return result;
    }
    function sendCallRequest() {
        if(validation()){
            let reqData = {}
            setConfirmLoading(true);
            if(inputValue?.time === ''){
                delete inputValue?.time
                reqData = {
                    ...inputValue
                }
            }else{
                reqData = {
                    ...inputValue
                }
            }
            reqData.type = 'CALL'
            props.callRequest(reqData).then(() =>{
                setTimeout(() => {
                    setConfirmLoading(false);
                    setConfirmBtnDisable(true)
                }, 2000)
                setTimeout(() => {
                    closeModal()
                    setConfirmLoading(false);
                    setConfirmBtnDisable(false)
                }, 7000)
            }).catch((err) => {
                setConfirmLoading(false);
            })
        }
    }
    function closeModal(){
        toggleCallBtn()
        setInputValue({
            fullName: "",
            phoneNumber: "",
            time:"",
        })
        setErrors({
            fullName: "",
            phoneNumber: "",
        })
        setShowTime(false)
    }
    function getInputValues({name, value}) {
        setInputValue({
            ...inputValue,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false,
        })
    }
    function saveValue(e) {
        const {name, value} = e.target
        if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setInputValue({
            ...inputValue,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false,
        })
    }
    function getDisabledHours() {
        let hours = [];
        for (let i = 0; i < 24; i++) {
            if (i < 9 || i > 18) {
                hours.push(i);
            }
        }
        return hours;
    }

    return <Modal centered
                  visible={callModelOpen}
                  title={staticTexts?.call_modal_title}
                  okText={staticTexts?.call_modal_send}
                  cancelText={staticTexts?.call_modal_cancel}
                  className={'call-modal'}
                  confirmLoading={confirmLoading}
                  closeIcon={<CloseModalIcon/>}
                  onOk={!confirmBtnDisable && sendCallRequest}
                  onCancel={closeModal}>

        <InputGroup
            inputType={'input'}
            type={"text"}
            value={inputValue.fullName}
            error={errors.fullName}
            name={'fullName'}
            placeholder={staticTexts?.contacts_page_placeholder_name}
            maxLength={100}
            onChange={saveValue}/>
        <div className={'bottom-block'}>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={inputValue.phoneNumber}
                error={errors.phoneNumber}
                name={'phoneNumber'}
                placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                maxLength={20}
                onChange={saveValue}/>
            {showTime ?  <InputGroup inputType={'wrapper'}
                                     className={`${errors?.time ? 'errors' : ""}`}
                                     value={inputValue?.time}>
                    <TimePicker allowClear={false}
                                disabledHours={getDisabledHours}
                                placeholder={staticTexts?.call_time_picker_placeholder}
                                minuteStep={10}
                                value={inputValue?.time}
                                format={'HH:mm'}
                                className={`${errors?.time ? 'error' : ""}`}
                                popupClassName="popup-input-time"
                                onSelect={(time) => getInputValues({name: 'time', value: time})}/>
                </InputGroup> :
                <div className={'checkboxWrapper'}>
                    <Checkbox onChange={() => setShowTime(!showTime)}
                              checked={showTime}>
                        {staticTexts?.call_time_placeholder}
                    </Checkbox>
                </div>
            }
        </div>

    </Modal>

}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    callRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallModel)