import './AddressWrapper.scss'
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {LocationIcon, ParkingIcon} from "../../assets/images";
import React from "react";
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";


function AddressWrapper(props) {
    const {staticTexts,addressFirst} = props

    return   <div className={'AddressWrapper'}>
        <div className={'address-left-wrapper'}>
            <Breadcrumb>
                <Breadcrumb.Item><Link
                    to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{addressFirst}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
        <div className={'address-right-wrapper'}>
            <Link to={getUrlWithLocal('/floor/-1')} className={'item-card'}>
                <div className={'parking-icon-wrapper'}>
                    <ParkingIcon />
                </div>
            </Link>
            <Link to={getUrlWithLocal('/address')} className={'item-card'}>
                <div className={'icon-wrapper'}>
                    <LocationIcon/>
                </div>
            </Link>
        </div>
    </div>
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressWrapper);