// Import packages
import React from "react";

// Import utils
import {history} from "../../../configs/history";

// Import styles
import "./buttons.scss";
import {
    SimilarLeftSlider,
    SimilarRightSlider
} from "../../../assets/images";


export function PrimaryButton(props) {
    const {title, className, link, cb, loading, disabled} = props;
    return <button className={`PrimaryButton ${className ? className : ''}`}
                   disabled={disabled || false}
                   onClick={(e) => {
                       if (link) {
                           history.push(link)
                       } else {
                           if (!loading && cb) {
                               e.preventDefault();
                               e.stopPropagation()
                               cb();
                           }
                       }
                   }}>
            <span className="title">{title}</span>
    </button>
}
export function SliderButton(props) {
    const {className, cbPrev, cbNext, disabledPrev, disabledNext} = props;
    return <div className={`arrows ${className? className : ""}`}>
        <button className={`slider-btn  prev-btn ${disabledPrev ? 'disabled' : ''}`}
                onClick={cbPrev}
                disabled={disabledPrev}
        >
            <SimilarLeftSlider/>
        </button>
        <button
            className={`slider-btn next-btn ${disabledNext ? 'disabled' : ''}`}
            disabled={disabledNext}
            onClick={cbNext}>
            <SimilarRightSlider/>
        </button>
    </div>
}


