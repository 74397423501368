// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import assets
import "./calculator.scss"
import {DramIcon} from "../../assets/images";

// Import Components
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";
import {PrimaryButton} from "../../components/ui-elements/buttons/Buttons";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetBanks, GetHouseById} from "../../redux/actions";
import {HYPOTHEC_TERM_TYPES, REPAYMENT_TYPES} from "../../constants/constants";
import {calculator, priceWithCommas} from "../../utils/helperFunctions";
import {LIMIT_MAX_PRICE, LIMIT_MIN_PRICE} from "../../constants/acceptedConsts";
import AddressWrapper from "../../components/AddressWrapper/AddressWrapper";


class Calculator extends Component {
    constructor(props) {
        super(props);
        const banksList = (props?.hypothecAmount && props?.hypothecAmount > LIMIT_MIN_PRICE) ? props?.banksList.filter(item => item?.id !== "60800da20a96c8177c9b0837") : props?.banksList
        this.state = {
            fieldsData: {
                hypothecAmount: this.props?.location.state ? this.props?.location.state : "",
                advancePayment: this.props?.location.state && banksList?.[0]?.advancePayment ? (this.props?.location.state * banksList?.[0]?.advancePayment / 100) : "",
                monthsCount: (Number.isInteger(banksList?.[0]?.monthsCount / 12) ?
                    banksList?.[0]?.monthsCount / 12 :
                    banksList?.[0]?.monthsCount) || "",
                termType: banksList?.length && banksList?.[0]?.monthsCount ? (Number.isInteger(banksList?.[0]?.monthsCount / 12) ? "YEAR" : "MONTH") : undefined,
                repaymentType: 'ANNUITY',
                bank: banksList?.[0]?.id || undefined,
                percent: banksList?.[0]?.id === "61016487c59667735094f1de" && props?.hypothecAmount > LIMIT_MAX_PRICE ? banksList?.[0]?.percent + 1 : (banksList?.[0]?.percent || ""),
            },
            errors: {
                hypothecAmount: false,
                advancePayment: false,
                monthsCount: false,
                termType: false,
                repaymentType: false,
                bank: false,
                percent: false,
            },
            rightCardVisibility: false
        };
        this.getInputValues = this.getInputValues.bind(this);
        this.getPriceValues = this.getPriceValues.bind(this);
        this.clearData = this.clearData.bind(this);
        this.calculate = this.calculate.bind(this);
        this.getSelectValue = this.getSelectValue.bind(this);
        this.getSelectValue = this.getSelectValue.bind(this);
        this.totalData = {};
    }

    componentDidMount() {
        this.props.GetBanks()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps?.hypothecAmount !== this.props?.hypothecAmount) {
            const banksList =  this.props?.hypothecAmount > LIMIT_MIN_PRICE ? this.props?.banksList.filter(item => item?.id !== "60800da20a96c8177c9b0837") : this.props?.banksList
            let bank = banksList.find(item => item.id === this.state?.fieldsData?.bank)
            this.props?.hypothecAmount > LIMIT_MIN_PRICE && this.state?.fieldsData?.bank === "60800da20a96c8177c9b0837" && (bank = banksList?.[0])

            this.setState({
                fieldsData: {
                    ...this.state.fieldsData,
                    hypothecAmount: this.props?.hypothecAmount,
                    advancePayment: this.props?.hypothecAmount && bank?.advancePayment ? (this.props?.hypothecAmount * bank?.advancePayment) / 100 : "",
                    monthsCount: (Number.isInteger(bank?.monthsCount / 12) ?
                        bank?.monthsCount / 12 :
                        bank?.monthsCount) || "",
                    termType: banksList?.length && bank?.monthsCount ? (Number.isInteger(bank?.monthsCount / 12) ? "YEAR" : "MONTH") : undefined,
                    repaymentType: 'ANNUITY',
                    bank: bank?.id || undefined,
                    percent: bank?.id === "61016487c59667735094f1de" && this.props?.hypothecAmount > LIMIT_MAX_PRICE ? bank?.percent + 1 : (bank?.percent || ""),
                }
            }, () => this.state.rightCardVisibility && this.calculate())
        }
    }


    getPriceValues(e) {
        const {name, value} = e.target
        let advancePayment = ""
        const bank = this.props.banksList.find(item => item.id === this.state.fieldsData?.bank)
        if(name === "hypothecAmount" && bank) {
            advancePayment = value.replace(/\D/g,'') * bank?.advancePayment / 100
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                advancePayment: advancePayment && advancePayment?.toFixed(0),
                [name]: value.replace(/\D/g,'')
            },
            errors: {
                ...this.state.errors,
                [name]: false
            }
        })
    }

    getInputValues({name, value}) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [name]: value
            },
            errors: {
                ...this.state.errors,
                [name]: false
            }
        })
    }

    getSelectValue = (name, value) => {
        const newState = {}
        const errorsState = {}
        if (name === "bank" && value) {
            newState[name] = value;
            const bank = this.props.banksList.find(item => item.id === value)
            newState.percent = bank?.id === "61016487c59667735094f1de" && this.props?.hypothecAmount > LIMIT_MAX_PRICE ? bank?.percent + 1 : bank?.percent
            newState.monthsCount = (Number.isInteger(bank?.monthsCount / 12) ?
                bank?.monthsCount / 12 : bank?.monthsCount)
            newState.termType = bank?.monthsCount ? (Number.isInteger(bank?.monthsCount / 12) ?
                "YEAR" : "MONTH") : undefined
            newState.advancePayment = bank?.advancePayment && this.state.fieldsData?.hypothecAmount * bank?.advancePayment / 100 || ""
            errorsState.percent = false
            errorsState.bank = false
        } else {
            newState[name] = value
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                ...newState
            },
            errors: {
                ...this.state.errors,
                ...errorsState,
                [name]: false
            }
        })

    }

    clearData() {
        const banksList = (this.props?.hypothecAmount && this.props?.hypothecAmount > LIMIT_MIN_PRICE) ?
            this.props?.banksList.filter(item => item?.id !== "60800da20a96c8177c9b0837") :
            this.props?.banksList
        this.setState({
            fieldsData: {
                hypothecAmount: this.props?.hypothecAmount ? this.props?.hypothecAmount : "",
                advancePayment: this.props?.hypothecAmount && banksList?.[0]?.advancePayment ? (this.props?.hypothecAmount * banksList?.[0]?.advancePayment / 100) : "",
                monthsCount: (Number.isInteger(banksList?.[0]?.monthsCount / 12) ?
                    banksList?.[0]?.monthsCount / 12 :
                    banksList?.[0]?.monthsCount) || "",
                termType: banksList?.length && banksList?.[0]?.monthsCount ? (Number.isInteger(banksList?.[0]?.monthsCount / 12) ? "YEAR" : "MONTH") : undefined,
                repaymentType: 'ANNUITY',
                bank: banksList?.[0]?.id || undefined,
                percent: banksList?.[0]?.id === "61016487c59667735094f1de" && this.props?.hypothecAmount > LIMIT_MAX_PRICE ? banksList?.[0]?.percent + 1 : (banksList?.[0]?.percent || ""),
            },
            errors: {
                hypothecAmount: false,
                advancePayment: false,
                monthsCount: false,
                termType: false,
                repaymentType: false,
                bank: false,
                percent: false,
            },
            rightCardVisibility: false
        }, )
        this.props.calculate()
    }

    validate() {
        const {fieldsData} = this.state
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {

            if (key !== "advancePayment" && !fieldsData[key]) {
                errors[key] = true;
                result = false;
            } if (fieldsData.advancePayment && fieldsData?.hypothecAmount && fieldsData?.hypothecAmount - fieldsData?.advancePayment <= 0) {
                errors.advancePayment = true;
                result = false;
            }

        })
        this.setState({
            errors: errors
        })
        return result;
    }

    calculate() {
        const {hypothecAmount, advancePayment, monthsCount, termType, repaymentType, percent} = this.state.fieldsData
        if (this.validate()) {
            let newTerm = monthsCount
            let newHypothecAmount = advancePayment ? hypothecAmount - advancePayment : hypothecAmount
            let newRepaymentType = repaymentType === "ANNUITY" ? 0 : 1
            if (termType === "YEAR") {
                newTerm = Number(monthsCount) * 12
            }
            this.totalData = calculator(newHypothecAmount, percent, newTerm, newRepaymentType)
            this.setState({
                rightCardVisibility: true,
            })
            this.props.getCalculatorMonthlyPrice && this.props?.getCalculatorMonthlyPrice(this.totalData?.result?.totalPaymentInMonth)
        }
    }

    render() {
        const {fieldsData, errors, rightCardVisibility} = this.state
        const { staticTexts, fromHouseDetails} = this.props
        let banksList = (this.props.hypothecAmount && this.props.hypothecAmount > LIMIT_MIN_PRICE) ?
            this.props?.banksList.filter(item => item?.id !== "60800da20a96c8177c9b0837") :
            this.props?.banksList
        const bankOptions = !!banksList && banksList.map(item => ({
            id: item?.id,
            name: item?.title
        }));

        this.termTypeOptions = Object.keys(HYPOTHEC_TERM_TYPES).map(key => ({
            id: key,
            name: this.props?.staticTexts?.[HYPOTHEC_TERM_TYPES[key]]
        }));
        this.repaymentTypeOptions = Object.keys(REPAYMENT_TYPES).map(key => ({
            id: key,
            name: this.props?.staticTexts?.[REPAYMENT_TYPES[key]]
        }));
        return <div className={'CalcWrapper'}>
            <div className={'ContentWrapper'}>
                <AddressWrapper addressFirst ={staticTexts?.rout_item_calculator_text}/>
                <div className={"CalcMainContent"}>
                    <div className={'background-block'}>
                        <div className={'text-block'}>
                            <div className={'title'}>{staticTexts?.calculator_title}</div>
                            <div className={'description '}>{staticTexts?.calculator_title_description}</div>
                        </div>
                    </div>
                    <div className={'LeftSide'}>
                        <div className="calc-title">
                            <span>{staticTexts?.calculator_left_title}</span>
                        </div>
                        <div className={'fields-horizontal'}>
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={fieldsData.bank}
                                name={'bank'}
                                error={errors.bank}
                                label={staticTexts?.calculator_bank_title_label}
                                placeholder={staticTexts?.calculator_bank_title_placeholder}
                                onChange={this.getSelectValue}
                                options={bankOptions}/>
                            <InputGroup
                                inputType={'input'}
                                type={"number"}
                                name={"percent"}
                                value={fieldsData?.percent}
                                error={errors.percent}
                                label={staticTexts?.calculator_hypothec_percent_label}
                                placeholder={staticTexts?.calculator_hypothec_percent_placeholder}
                                onChange={this.getInputValues}
                            />

                        </div>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={priceWithCommas(fieldsData.hypothecAmount)}
                            name={'hypothecAmount'}
                            error={errors.hypothecAmount}
                            // minValue={1}
                            disabled={fromHouseDetails}
                            label={staticTexts?.calculator_hypothec_price_label}
                            placeholder={staticTexts?.calculator_hypothec_price_placeholder}
                            onChange={this.getPriceValues}/>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={priceWithCommas(fieldsData.advancePayment)}
                            name={'advancePayment'}
                            error={errors.advancePayment}
                            minValue={1}
                            label={staticTexts?.calculator_hypothec_advancePayment_label}
                            placeholder={staticTexts?.calculator_hypothec_advancePayment_placeholder}
                            onChange={this.getPriceValues}/>
                        <div className={'fields-horizontal'}>
                            <InputGroup
                                inputType={'input'}
                                type={"number"}
                                value={fieldsData?.monthsCount}
                                error={errors.monthsCount}
                                minValue={1}
                                name={'monthsCount'}
                                label={staticTexts?.calculator_hypothec_date_label}
                                placeholder={staticTexts?.calculator_hypothec_date_placeholder}
                                onChange={this.getInputValues}
                            />
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={fieldsData.termType}
                                name={'termType'}
                                error={errors.termType}
                                label={staticTexts?.calculator_hypothec_type_label}
                                placeholder={staticTexts?.calculator_hypothec_type_placeholder}
                                onChange={this.getSelectValue}
                                options={this.termTypeOptions}/>
                        </div>
                        <div className="btnsWrapper">
                            <PrimaryButton className={'dark'}
                                           cb={this.clearData}
                                           title={staticTexts?.calculator_cancel_btn}/>
                            <PrimaryButton
                                title={staticTexts?.calculator_calculate_btn}
                                cb={this.calculate}
                                disabled={fromHouseDetails && !fieldsData?.hypothecAmount}
                            />
                        </div>
                    </div>
                    {rightCardVisibility &&
                    <div className={'RightSide'}>
                        <div className="calc-title">
                            {staticTexts?.calculator_right_title}
                        </div>
                        <div className={'result-box'}>
                            {fieldsData?.repaymentType === "ANNUITY" && this.totalData?.result?.totalPaymentInMonth &&
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_monthly_payment_title}</div>
                                <div
                                    className={'result-box-row-text'}>{priceWithCommas(this.totalData?.result?.totalPaymentInMonth)}<DramIcon/>
                                </div>
                            </div>}
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{`${staticTexts?.calculator_right_box_hypothec_date_title} (${fieldsData?.termType === "MONTH" ?
                                    staticTexts?.calculator_hypothec_type_mount : staticTexts?.calculator_hypothec_type_year})`}</div>
                                <div
                                    className={'result-box-row-text'}>{fieldsData?.monthsCount} {fieldsData?.termType === "MONTH" ?
                                    staticTexts?.calculator_hypothec_type_mount : staticTexts?.calculator_hypothec_type_year}</div>
                            </div>
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_hypothec_percent_title}</div>
                                <div className={'result-box-row-text'}>{fieldsData?.percent} %</div>
                            </div>
                            {!!fieldsData?.hypothecAmount &&<div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_main_price_title}</div>
                                <div className={'result-box-row-text'}>{priceWithCommas(fieldsData?.hypothecAmount)}<DramIcon/></div>
                            </div>}
                            {!!fieldsData?.advancePayment && <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_advance_payment_title}</div>
                                <div className={'result-box-row-text'}>{priceWithCommas(fieldsData?.advancePayment)}<DramIcon/></div>
                            </div>}
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_percent_title}</div>
                                <div
                                    className={'result-box-row-text'}>{priceWithCommas(this.totalData?.result?.totalPaymentPercent)}<DramIcon/>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {rightCardVisibility && !!this.totalData?.monthsResult?.length && <div className="results-table">
                    <table>
                        <thead>
                        <tr>
                            <th>{staticTexts?.calculator_table_column_month}</th>
                            <th>{staticTexts?.calculator_table_column_percent}</th>
                            <th>{staticTexts?.calculator_table_main_amount}</th>
                            <th>{staticTexts?.calculator_table_monthly_payment}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.totalData?.monthsResult.map((month, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                {!!month?.length && month.map((item, i) => {
                                    return <td key={i}>
                                        {priceWithCommas(item)}
                                    </td>
                                })}
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'contact',
    'banksList',
]);

const mapDispatchToProps = {
    GetHouseById,
    GetBanks,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);