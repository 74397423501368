// Import packages
import React, {useEffect} from "react";
import {connect} from 'react-redux';

// Import styles
import './privacy-policy.scss';

// Import components

//import utils
import {getPropsFromState} from "../../redux/mapStateToProps";

function PrivacyPolicy(props) {
    const {contact} = props;
    useEffect(  () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    })

    return <section className='PrivacyWrapper'>
        <div className="privacy-policy-text" dangerouslySetInnerHTML={{__html: contact?.privacyPolicy}}/>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'contact',
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
