// Import packages
import React, {useEffect, useLayoutEffect, useState, useRef} from "react";
import Slider from "react-slick";

// Import styles
import "./images-slider.scss";

//Import Images
import {ArrowLeftIcon, ArrowRightIcon, ClearIcon, CloseModalIcon} from "../../../assets/images";
// Import components
import {Modal} from "antd";

//import utils
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";

export function ImagesSlider(props) {
    const {closeModal, visible, modalImagePathList, modalCurrentImageIndex, title} = props;
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const [slideIndex, setSlideIndex] = useState("");
    let sliderRef = useRef(null);


    useEffect(() => {
        sliderRef.slickGoTo?.(modalCurrentImageIndex)
        setSlideIndex(modalCurrentImageIndex)
    }, [modalCurrentImageIndex])

    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (modalImagePathList?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };


    useLayoutEffect(() => {
        if (visible) {
            sliderRef && sliderRef.current && sliderRef.current.slickGoTo(modalCurrentImageIndex);
            setTimeout(() => {
                setShowCloseIcon(true)
            }, 250)
        } else {
            setShowCloseIcon(false);

        }
    }, [visible]);

    const settings = {
        dots: false,
        arrows: false,
        ref: slider => (sliderRef = slider),
        swipeToSlide: false,
        focusOnSelect: false,
        infinite: false,
        slidesToShow: 5,
    };

    return <Modal
        visible={visible}
        wrapClassName={'custom-modal'}
        closeIcon={<CloseModalIcon/>}
        onCancel={props.closeModal}>
        <div className={'slider-modal-wrapper'}>
            <div className="modal-header">
                <div className="modal-title">{title}</div>
                <button className={`dialog-closing-btn ${showCloseIcon ? 'show' : ''}`}
                        onClick={closeModal}>
                    <ClearIcon title={''}/>
                </button>
            </div>
            {visible && !!modalImagePathList?.length &&
            <div className="slider-wrapper">
                <div className="main-img-wrapper">
                    <div className="main-img-content">
                        <img src={generateFileMediaUrl(modalImagePathList[slideIndex]?.path)}
                             className={'main-img'} alt={"about"}/>
                    </div>
                </div>

                <div className="images-list-wrapper">
                    {!!modalImagePathList?.length && <Slider {...settings}>
                        {modalImagePathList?.map((item, index) => {
                            return <div key={item.id}
                                        className={`list-item ${(slideIndex === index) ? 'active' : ''}`}>
                                <img
                                    src={generateFileMediaUrl(item?.path)} className={'list-img'}
                                    onClick={() => setSlideIndex(index)} alt={"about"}/>
                            </div>
                        })}
                    </Slider>}
                    {modalImagePathList?.length > 5 &&
                    <>
                        <button className={`slider-btn  prev-btn`}
                                onClick={() => prevSlide(slideIndex)}
                                disabled={slideIndex === 0}
                        >
                            <ArrowLeftIcon/>
                        </button>
                        <button
                            className={`slider-btn next-btn`}
                            disabled={slideIndex === modalImagePathList?.length - 1}
                            onClick={() => nextSlide(slideIndex)}>
                            <ArrowRightIcon/>
                        </button>
                    </>
                    }
                </div>
            </div>
            }
        </div>
    </Modal>
}
