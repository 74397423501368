import {_urlAboutSlider, _urlSlider, request} from "../api";
import {SLIDER_CONSTS} from "../constants";

export const GetMainSlider = () => {
    const requestData = {
        url: `${_urlSlider}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: SLIDER_CONSTS.GET_MAIN_SLIDER,
                    payload: data
                })
            })
    };
};

export const GetAboutSlider = () => {
    const requestData = {
        url: `${_urlAboutSlider}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: SLIDER_CONSTS.GET_ABOUT_SLIDER,
                    payload: data
                })
            })
    };
};
