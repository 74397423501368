// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//import styles
import "./address.scss";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {FullscreenControl, Map, Placemark, TypeSelector, YMaps} from "react-yandex-maps";
import {RETROSHIN_LINK} from "../../constants/constants";

function Locations(props) {
    const {languages, selectedLanguage,} = props
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    const mapData = {
        center: [40.211587, 44.485395],
        zoom: 16,
    }

    return <div className={`locations-wrapper`}>
        <div className={'map-wrapper'}>
            <YMaps query={{
                apikey: 'c5f70ecb-be1a-4260-9292-9ecafceda596',
                lang: 'en'
            }} >
                <Map defaultState={mapData} cursors={"inherit"} className={'yandexMap'} >
                    <Placemark
                        geometry={[40.211587, 44.485395]}
                        classname={'customIcon'}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref:RETROSHIN_LINK.iconUrl,
                            iconImageSize: [40,55],
                            iconImageOffset: [-15, -60],
                        }}
                    />
                    <FullscreenControl options={{
                        position:{
                            bottom:28,
                            right: 26,
                        },
                    }}/>
                    <TypeSelector options={{
                        float: 'right'
                    }} />
                </Map>
            </YMaps>
        </div>
    </div>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'contact',
        'languages',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Locations);
