// import packages
import React from "react";
import {connect} from 'react-redux';
import {Link, NavLink} from "react-router-dom";

//import styles
import "../assets/styles/components/header.scss";
import {
    CollapseIcon,
    CollapseLangIcon,
    FbIcon,
    FilterClose,
    InstaIcon,
    Logo, MobileInstIcon,
    MobileLogo, PhoneDropDownIcon, ViberIcon, WhatsUppIcon,
} from "../assets/images"

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";

import {Collapse, Dropdown, Menu} from "antd";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {generateFileMediaUrl} from "../utils/generateMediaUrl";
import {history} from "../configs/history";
import {ChangeLanguage} from "../redux/actions";
import ClockBlocks from "./ClockBlocks";

const {Panel} = Collapse;

function Header(props) {
    const {menu, contact, staticTexts,languages, selectedLanguage,} = props;
    const phone = contact?.phoneNumber?.replace(/\D/g,'').substring(0,12)
    const areaCode = phone?.substring(0, 3);
    const code = phone?.substring(3,5);
    const content = phone?.substring(5, 7);
    const content1 = phone?.substring(7, 9);
    const content2 = phone?.substring(9, 12);

    const menuLanguage = !!languages?.length && (
        <Menu className="languages-dropdown">
            {!!languages?.length && languages?.map((item) => {
                return selectedLanguage?.code !== item.code &&
                    <Menu.Item key={item.id}
                               onClick={() => {
                                   history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                   props.ChangeLanguage(item)
                               }}
                               className={'language-dropdown-item'}>
                        <img src={generateFileMediaUrl(item?.iconPath?.path)} alt=""/>
                        <div>{item.code}</div>
                    </Menu.Item>
            })
            }
        </Menu>
    );

    const customMenu = !!menu?.length && (
        <Menu className={'custom-menu'}>
            {!!menu?.length && menu?.map((item) => {
                return <Menu.Item key={item.id}>
                    {item?.pageSlug ?
                        <NavLink to={getUrlWithLocal(item?.pageSlug?.startsWith("/") ? `/custom-page${item?.pageSlug}` : `/custom-page/${item?.pageSlug}`)}
                                 className="menu-item"
                                 activeClassName='active'>{item?.title}</NavLink> : ""}
                </Menu.Item>
            })}
        </Menu>
    );
    const callMenu = (
        <Menu>
              <Menu.Item className={'callDropDown'}>
                  <a href={`tel:+${phone}`} target={'_blank'}>
                      <PhoneDropDownIcon/>
                      <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                  </a>
                  <a href={`viber://chat?number=%2B${phone}`} target={'_blank'}>
                      <ViberIcon/>
                      <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                  </a>
                  <a href={`https://wa.me/+${phone}`} target={'_blank'}>
                      <WhatsUppIcon />
                      <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                  </a>
                </Menu.Item>
        </Menu>
    );
    return <>
        <div className="headerWrapper">
            <span className={'underLine'}/>
            <div className="desktop-menu">
                <div className="left-side">
                    <Link to={getUrlWithLocal("/home")} className="logo-wrapper">
                        <img src={Logo} alt=""/>
                    </Link>
                </div>
               <ClockBlocks/>
                <div className="right-side">
                    <div className="top-side">
                        <ul className="menu-items-list">
                            <li>
                                <NavLink to={getUrlWithLocal("/home")} className="menu-item"
                                         activeClassName='active'>{staticTexts?.header_item_home}</NavLink>
                            </li>
                            <li>
                                <NavLink to={getUrlWithLocal("/buildings")} className="menu-item"
                                         activeClassName='active'>{staticTexts?.header_item_project}</NavLink>
                            </li>
                            <li>
                                <NavLink to={getUrlWithLocal("/contact")} className="menu-item"
                                         activeClassName='active'>{staticTexts?.header_item_contact}</NavLink>
                            </li>
                            <li>
                                {!!menu?.length && <Dropdown overlay={customMenu}
                                                            overlayClassName={''}
                                                            placement="bottom"
                                                            size={'small'}>
                                    <div className="menu-item">
                                        {staticTexts?.header_item_more}
                                        <CollapseIcon className={'arrow-icon'} title={''}/>
                                    </div>
                                </Dropdown>}
                            </li>
                            <li>
                                <Dropdown overlay={callMenu}
                                          overlayClassName={''}
                                          trigger={['click']}
                                          placement="bottom"
                                          size={'small'}>
                                    <div className="header-phone menu-item">
                                        <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                                    </div>
                                </Dropdown>
                            </li>
                            <li>
                                <Dropdown overlay={menuLanguage}
                                          trigger={['hover']}
                                          overlayClassName={'languages-wrapper'}
                                          placement="bottom"
                                          size={'small'}>
                                    <div className="dropdown-menu-item">
                                        {selectedLanguage?.iconPath &&
                                            <img src={generateFileMediaUrl(selectedLanguage?.iconPath?.path)} alt=""/>}
                                        <div>{selectedLanguage?.code}</div>
                                        <CollapseLangIcon className={'arrow-icon'} title={''}/>
                                    </div>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                    <div className={'bottom-side'}>
                        <span>{staticTexts?.header_item_info}</span>
                       <div className={'icon-wrapper'}>
                           <a href={'https://m.facebook.com/RetroShinAM'} target={'_blank'} rel="noreferrer"><FbIcon title=''/></a>
                           <a href={'https://www.instagram.com/retroshinam/'} target={'_blank'} rel="noreferrer"><InstaIcon title=''/></a>
                       </div>
                    </div>
                    <div>
                    </div>

                </div>
            </div>
            {/*Mobile Menu*/}
            <div className="mobile-menu">
                <div className="left-side">
                    <Link to={getUrlWithLocal("/home")} className="logo-wrapper">
                        <img src={MobileLogo} alt=""/>
                    </Link>

                </div>
                <div className={'burger-wrapper'}>
                    <a className={`burger ${!props.mobileMenuIsOpen ? 'open-menu' : 'close-menu'}`} onClick={props.toggleMobileMenu}/>
                </div>
            </div>
            <div className={`menu-dropdown ${props.mobileMenuIsOpen ? "open" : ""}`}>
                <span className={'close-icon'}  onClick={props.toggleMobileMenu}><FilterClose/></span>
                <ClockBlocks/>
                <ul className="menu-items-list">
                    <li>
                        <NavLink to={getUrlWithLocal("/home")}
                                 className="menu-item"
                                 activeClassName='active'
                                 onClick={props.toggleMobileMenu}>{staticTexts?.header_item_home}</NavLink>
                    </li>
                    <li>
                        <NavLink to={getUrlWithLocal("/filter")}
                                 className="menu-item"
                                 activeClassName='active'
                                 onClick={props.toggleMobileMenu}>{staticTexts?.homepage_about_button_houses}</NavLink>
                    </li>
                    <li>
                        <NavLink to={getUrlWithLocal("/buildings")}
                                 className="menu-item"
                                 activeClassName='active'
                                 onClick={props.toggleMobileMenu}>{staticTexts?.about_page_count_projects}</NavLink>
                    </li>
                    <li>
                        <NavLink to={getUrlWithLocal(`/about`)}
                                 className="menu-item"
                                 activeClassName='active'
                                 onClick={props.toggleMobileMenu}>{staticTexts?.header_item_about}</NavLink>
                    </li>
                    <li>
                        <NavLink to={getUrlWithLocal(`/contact`)}
                                 className="menu-item"
                                 activeClassName='active'
                                 onClick={props.toggleMobileMenu}>{staticTexts?.header_item_contact}</NavLink>
                    </li>
                    <li>
                        <NavLink to={getUrlWithLocal("/calculator")}
                                 className="menu-item"
                                 activeClassName='active'
                                 onClick={props.toggleMobileMenu}>{staticTexts?.footer_section_calculator}</NavLink>
                    </li>
                    <li>
                        {menu && !!menu.length &&
                        <Collapse expandIconPosition={'right'} ghost
                                  expandIcon={(value) => {
                                      return <CollapseIcon
                                          className={value.isActive ? 'rotate' : ''}/>
                                  }}>
                            <Panel header={<div className="menu-item">{staticTexts?.header_item_more}</div>}>
                                {!!menu?.length && menu?.map((item) => {
                                    return item?.pageSlug ?
                                        <NavLink
                                            key={item?.id}
                                            onClick={props.toggleMobileMenu}
                                            to={getUrlWithLocal(item?.pageSlug?.startsWith("/") ? item?.pageSlug : `/${item?.pageSlug}`)}
                                            className="menu-item"
                                            activeClassName='active'>{item?.title}</NavLink> : ""
                                })}
                            </Panel>
                        </Collapse>}
                    </li>
                    <li>
                        <Dropdown overlay={callMenu}
                                  overlayClassName={''}
                                  trigger={['click']}
                                  placement="bottom"
                                  size={'small'}>
                            <div className="header-phone menu-item">
                                <span className={'phone-number'}>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                            </div>
                        </Dropdown>
                        {/*<a href={`tel:${contact?.phoneNumber}`}>*/}
                        {/*    <div className="header-phone menu-item">*/}
                        {/*        <span className={'phone-number'}>{contact?.phoneNumber}</span>*/}
                        {/*    </div>*/}
                        {/*</ a>*/}
                    </li>
                    <li>
                        <div className={'mobil-icon-wrapper'}>
                            <a href={'https://m.facebook.com/RetroShinAM'} target={'_blank'} rel="noreferrer"><FbIcon title=''/></a>
                            <a href={'https://www.instagram.com/retroshinam/'} target={'_blank'} rel="noreferrer"><MobileInstIcon title=''/></a>
                        </div>
                    </li>
                    <li>
                        <div className={'mobil-languages-wrapper'}>
                            <div className={'select-item'}>{selectedLanguage?.code}</div>
                                {!!languages?.length && languages?.map((item) => {
                                    return selectedLanguage?.code !== item.code &&
                                        <div key={item.id}
                                                      onClick={() => {
                                                          history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                                          props.ChangeLanguage(item)
                                                      }}
                                                      className={'language-item'}>
                                            <div className={'language-text'}>{item.code}</div>
                                        </div>
                                })
                                }
                        </div>
                    </li>
                    <li>
                        <div className={'rights-block'}>© 2022. ГК «Ретрошин». Все права защищены.</div>
                    </li>
                </ul>
            </div>
        </div>

    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'staticTexts',
        'contact',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
