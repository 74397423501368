import {store} from "../redux/store";

export function getUrlWithLocal(path = "") {
    let pathname = path;
    if(!path.startsWith('/')) {
        pathname = "/"+path
    }
    const {selectedLanguage, languages} = store.getState().general || {}
    const selectedCode = selectedLanguage?.code || languages?.find(lg => lg.isMain)?.code || 'hy'
    return path ? `/${selectedCode}${pathname}` : `/${selectedCode}/home`
}


