import {ABOUT_CONSTS} from "../constants";

export const initialState = {
    about: {},
};

const about = (state = initialState, action) => {
    switch (action.type) {
        case ABOUT_CONSTS.GET_ABOUT:

            return {
                ...state,
                about: action.payload,
            };

        default:
            return state;
    }
};export default about
