import {UTIL_CONSTS} from "../constants";

export const initialState = {
    requestLoading: false,
    showLinearLoading: false,
    metaTags: {
        title: '',
        description: '',
        image:''
    },
};

const util = (state = initialState, action) => {
    switch (action.type) {
        case UTIL_CONSTS.START_LOADING:
            return {
                ...state,
                requestLoading: true
            };
        case UTIL_CONSTS.END_LOADING:
            return {
                ...state,
                requestLoading: false
            };
        case UTIL_CONSTS.SET_META_DATA:
            return {
                ...state,
                metaTags: {
                    title: action.payload.title || "",
                    description: action.payload.description || "",
                    image: action.payload.image || "",
                }
            };
        case UTIL_CONSTS.CLEAR_META_DATA:
            return {
                ...state,
                metaTags: initialState.metaTags
            };
        case UTIL_CONSTS.TOGGLE_LINEAR_LOADING:
            return {
                ...state,
                showLinearLoading: action.payload
            };
        default:
            return state;
    }
}; export default util
