import {_urlAreas, _urlBuildings, _urlFloors, _urlParking, _urlReservation, request} from "../api";
import {BUILDINGS_CONSTS, UTIL_CONSTS} from "../constants";
import {BUILDING_TYPE, MAX_AREA, MAX_PRICE, MIN_AREA, MIN_PRICE} from "../../constants/acceptedConsts";


export const GetBuildings = () => {
    const requestData = {
        url: `${_urlBuildings}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: BUILDINGS_CONSTS.GET_BUILDINGS,
                    payload: data
                })
                return data
            })
    };
};

export const GetBuildingByIdOrSlug = (id) => {
    const requestData = {
        url: `${_urlBuildings}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: BUILDINGS_CONSTS.GET_BUILDING_BY_ID,
                    payload: data
                })
                return data
            })
    };
};
export const GetFloorById = (id) => {
    const requestData = {
        url: `${_urlFloors}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return res && res.data
            })
    };
};
export const GetHouseById = (id) => {
    const requestData = {
        url: `${_urlAreas}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return  res && res.data
            })
    };
};
export const GetHouseBestOffers = () => {
    const requestData = {
        url: `${_urlAreas}/best-offers`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: BUILDINGS_CONSTS.GET_BUILDING_BEST_OFFER,
                    payload: data
                })
                return data
            })
    };
};
export const GetParkingById = (id) => {
    const requestData = {
        url: `${_urlParking}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return  res && res.data
            })
    };
};
export const ReserveHouse = (data) => {
    const requestData = {
        url: `${_urlReservation}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type: UTIL_CONSTS.END_LOADING})
        })
    }
};

export const ClearFilter = to => {
    return {
        type:BUILDINGS_CONSTS.CLEARS_FILTER,
        payload:to,
    }
}

export const FilterState = to => {
    return {
        type:BUILDINGS_CONSTS.STATE_FILTER,
        payload:to,
    }
}


export const GetHouses = ({
                              offset = 0,
                              limit = 20,
                              reset = true,
                              buildingSlug = null,
                              floors = undefined,
                              roomsCount = [],
                              minArea = MIN_AREA,
                              maxArea = MAX_AREA,
                              minPrice = MIN_PRICE,
                              maxPrice = MAX_PRICE,
                              statuses = [],
                              types = BUILDING_TYPE.Apartments,
                              sort = 'price',
                          } = {}) => {
    const requestData = {
        url: `${_urlAreas}?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    buildingSlug && (requestData.url += `&buildingSlug=${buildingSlug}`);
    floors  && (requestData.url += `&floors=${floors}`);
    roomsCount && roomsCount.length && (requestData.url += `&roomsCount=${roomsCount.join(',')}`);
    minArea && minArea !== MIN_AREA && (requestData.url += `&minArea=${minArea}`);
    maxArea && maxArea !== MAX_AREA && (requestData.url += `&maxArea=${maxArea}`);
    minPrice && minPrice !== MIN_PRICE &&  (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && maxPrice !== MAX_PRICE && (requestData.url += `&maxPrice=${maxPrice}`);
    sort && (requestData.url += `&sort=${sort}`);
    types && (requestData.url += `&types=${types}`);
    statuses?.length && (requestData.url += `&statuses=${statuses?.join(",")}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: BUILDINGS_CONSTS.GET_HOUSES,
                    payload: {
                        data: data?.areas,
                        hasMore: data?.areas?.length === limit,
                        count: data?.count,
                        availableCount: data?.availableCount,
                        reservedCount: data?.reservedCount,
                        reset,
                        buildingSlug,
                        floors,
                        roomsCount,
                        minArea,
                        maxArea,
                        minPrice,
                        maxPrice,
                        sort,
                        types,
                        statuses,
                    }
                })
            })
    };
};
