//----------------------------------4Steps-server-------------------------------------------------

// const ADMIN_URL ="http://173.249.20.192:9500/api/v1";
// const BASE_URL =  "http://173.249.20.192:9600/api/v1";
// const HOST_MEDIA_URL = "http://173.249.20.192:9500";

//-----------------------------------Retroshin- server---------------------------------------------

// const ADMIN_URL ="http://104.248.81.88:3002/api/v1";
// const BASE_URL =  "http://104.248.81.88:3001/api/v1";
// const HOST_MEDIA_URL = "http://104.248.81.88:3002";

//-----------------------------------Retroshin - official- server----------------------------------

const ADMIN_URL ="https://api.retroshin.am/admin/api/v1";
const BASE_URL =  "https://api.retroshin.am/front/api/v1";
const HOST_MEDIA_URL = "https://api.retroshin.am/admin";



export const _hostMedia = HOST_MEDIA_URL;
export const _urlLanguage = BASE_URL + "/languages";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlMenu = BASE_URL + "/menu";
export const _urlBadges = BASE_URL + "/badges";
export const _urlBanks = BASE_URL + "/banks";
// export const _urlCustomPages = BASE_URL + "/custom-pages";
export const _urlSlider = BASE_URL + "/slider";
export const _urlAbout = BASE_URL + "/about";
export const _urlContact = BASE_URL + "/contact";
export const _urlBuildings = BASE_URL + "/buildings";
export const _urlFloors = BASE_URL + "/floors";
export const _urlAreas = BASE_URL + "/areas";
export const _urlParking = BASE_URL + "/parking";
export const _urlReservation = BASE_URL + "/reservation";
export const _urlSubscribers = BASE_URL + "/subscribers";
export const _urlAboutSlider = BASE_URL + "/about-slider";
export const _urlProject = BASE_URL + '/projects';
export const _urlCallRequest = BASE_URL + '/call-request';
export const _urlRateList = BASE_URL + '/rates-list';

