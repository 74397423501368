// Import packages
import React, { Component } from "react";
import { Route, Switch, Router, Redirect, } from "react-router-dom";
import { store } from "../redux/store";

// Import utils
import { history } from "../configs/history";

// Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/homepage/Homepage";
import Buildings from "../containers/buildings/Buildings";
import FloorDetails from "../containers/floorDetails/FloorDetails";
import HouseDetails from "../containers/houseDetails/HouseDetails";
import HousesFilter from "../containers/housesFilter/HousesFilter";
import About from "../containers/about/About";
import Calculator from "../containers/calculator/Calculator";
import Contact from "../containers/contact/Contact";
import Address from "../containers/address/Address";
import CustomPage from "../containers/customPages/CustomPage";
import PrivacyPolicy from "../containers/privacy/PrivacyPolicy";
import NotFound from "../containers/notFound/NotFound";


const locales = ['hy', 'en', 'ru']
export default class Routes extends Component {
    render () {
        return <Router history={history}>
            <Layout>
                <Route path={"/:locale?"} component={ChildrenRoutes}/>
            </Layout>
        </Router>
    }
}

class ChildrenRoutes extends Component {
    render () {
        const { location } = this.props
        const path = location.pathname
        const { selectedLanguage, languages } = store.getState().general || {}
        const selectedCode = selectedLanguage?.code || languages?.find(lg => lg.isMain)?.code || 'hy'
        const actualLocals = (languages && languages.length && languages.map(lg => lg.code)) || locales
        if (!path || path === '/') {
            history.push(`/${selectedCode}`)
            return null
        }
        let locale = path.split('/')[1]


        if (!actualLocals.includes(locale)) {
            const newPath = `/${selectedCode}${path}`
            history.push(newPath)
            return null
        }
        return <Switch>
            <Redirect exact from={`/${locale}`} to={`/${locale}/home`}/>
            <Route exact path={`/${locale}/home`} component={Homepage}/>
            <Route exact path={`/${locale}/about`} component={About}/>
            <Route exact path={`/${locale}/contact`} component={Contact}/>
            <Route exact path={`/${locale}/filter`} component={HousesFilter}/>
            <Route exact path={`/${locale}/calculator`} component={Calculator}/>
            <Route exact path={`/${locale}/address`} component={Address}/>
            <Route exact path={`/${locale}/buildings`} component={Buildings}/>
            <Route exact path={`/${locale}/floor/:num`} component={FloorDetails}/>
            <Route exact path={`/${locale}/floor/:num`} component={FloorDetails}/>
            <Route exact path={`/${locale}/floor/:num/place/:house`} component={HouseDetails}/>
            <Route exact path={`/${locale}/floor/:num/place/:house`} component={HouseDetails}/>
            <Route exact path={`/${locale}/privacy-policy`}
                   component={PrivacyPolicy}/>
            <Route path={`/${locale}/custom-page/:slug`} component={CustomPage}/>
            <Route exact path={`/${locale}/404`} component={NotFound}/>

            <Route exact path={'*'} component={NotFound}/>
        </Switch>

    }
}
