import './Currency.scss'
import React, {useEffect, useState} from "react";
import {InputGroup} from "../ui-elements/input-group/inputGroup";
import {ChangeIcon, DecreaseArr, IncreaseArr} from "../../assets/images";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {CURRENCY} from "../../constants/constants";


function Currency(props){
    const {currency,staticTexts} = props
    const [date,setDate] = useState()
    const [fieldsData, setFieldsData] = useState({
        currencyFrom:'AMD',
        currencyTo:'USD',
        currencyFromAmount: "",
        currencyToAmount: "",
    })

    useEffect(() => {
        let data = new Date()
        let today = data?.toLocaleDateString('da-DK')
        setDate(today)
    }, []);

   function  getInputValues(name,value) {
       if(name === 'currencyFromAmount' || name === 'currencyToAmount'){
           if (value && !/^[\.0-9]*$/.test(value)) {
               return
           }
       }
       const data = {
           ...fieldsData,
           [name]: value
       }

       if (name.startsWith('currencyFrom')) {
           const currValue = name.includes('Amount') ? +value : fieldsData.currencyFromAmount
           const curr = name.includes('Amount') ? fieldsData.currencyFrom : value
           const rate = `${curr}_${fieldsData.currencyTo}`

           data.currencyToAmount = Math.floor(currValue * (currency.find((item) => item.currency.includes(rate))?.rate || 1) * 100) / 100
       } else if (name.startsWith('currencyTo')) {
           const curr = name.includes('Amount') ? fieldsData.currencyFrom : value
           if(name === 'currencyTo'){
               const currValue= name.includes('Amount') ? +value : fieldsData.currencyFromAmount
               const rate = `${fieldsData.currencyFrom}_${curr}`
               data.currencyToAmount = Math.floor(currValue * (currency.find((item) => item.currency.includes(rate) )?.rate || 1) * 100) / 100
           }else {
               const rate = `${fieldsData.currencyTo}_${curr}`
               const currValue= name.includes('Amount') ? +value : fieldsData.currencyToAmount
               data.currencyFromAmount = Math.floor(currValue * (currency.find((item) => item.currency.includes(rate) )?.rate || 1) * 100) / 100
           }
       }
       setFieldsData(data)
    }

    function handleClick(){
        setFieldsData({
            ...fieldsData,
            currencyFrom: fieldsData.currencyTo,
            currencyTo: fieldsData.currencyFrom,
            currencyToAmount: fieldsData.currencyFromAmount,
            currencyFromAmount: fieldsData.currencyToAmount,
        })
    }
     const Options =  [
        {name: 'AMD', id: 'AMD'},
        {name: 'USD', id: 'USD'},
        {name: 'EUR', id: 'EUR'},
        {name: 'RUB', id: 'RUB'},
    ]

    return <div className={'Currency'}>
        <div className={'currency-exchange'}>
            <div className={'change-block'}>
                <span className={'title'}>{staticTexts?.filter_currency_exchange}</span>
            </div>
            {
                currency?.filter((item) => CURRENCY.includes(item?.currency)).map((item) => {
                    return <tr key={item?.currency} className={'currency-wrapper'}>
                            <td className={'country'}>{item?.currency?.replace('_', ' / ')}</td>
                         <td  className={'purchase'}>{item?.rate}</td>
                        {
                            item?.difference < 0 ?
                                <td className={'sale'}><DecreaseArr/>{Math.abs(item?.difference || 0)?.toFixed(2)}</td>
                                : <td key={item} className={'sale'}><IncreaseArr/>{item?.difference?.toFixed(2)}</td>
                        }
                    </tr>
                })
            }
        </div>
        <div className={'calculator-wrapper'}>
            <div className={'calculator-title'}>{staticTexts?.filter_currency_calculator_title}</div>
            <div className={'calculator'}>
                <div className={'left-block'}>
                    <InputGroup
                        showSearch={false}
                        inputType={'select'}
                        value={fieldsData?.currencyFrom}
                        name={'currencyFrom'}
                        placeholder={staticTexts?.filter_currency_input_placeholder}
                        onChange={(name,value) => getInputValues(name,value)}
                        options={Options}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.currencyFromAmount}
                        name={'currencyFromAmount'}
                        maxLength={30}
                        placeholder={''}
                        onChange={(e) => getInputValues('currencyFromAmount', e.target.value)}
                    />
                </div>
                <div className={'change-icon'} onClick={handleClick}>
                    <ChangeIcon/>
                </div>
                <div className={'right-block'}>
                    <InputGroup
                        showSearch={false}
                        inputType={'select'}
                        value={fieldsData?.currencyTo}
                        name={'currencyTo'}
                        placeholder={staticTexts?.filter_currency_input_placeholder}
                        onChange={(name,value) => getInputValues(name,value)}
                        options={Options}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.currencyToAmount}
                        name={'currencyToAmount'}
                        maxLength={30}
                        placeholder={''}
                        onChange={(e) => getInputValues('currencyToAmount', e.target.value)}
                    />
                </div>
            </div>
            <div className={'date'}>{staticTexts?.filter_currency_data} {date}</div>
        </div>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'currency',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Currency);