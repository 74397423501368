// Import packages
import React from "react";
import {connect} from "react-redux";

// Import styles
import "./house-card.scss";

import {DramIcon} from "../../assets/images";
// Import Components

// Import utils
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {priceWithCommas} from "../../utils/helperFunctions";
import {resizeImage} from "../../utils/resizeImage";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {Link} from "react-router-dom";


function SlidersCard(props) {
    const {areas, staticTexts} = props



    return <Link to={
        {
            pathname: getUrlWithLocal(`/floor/${areas?.floor?.number}/place/${areas?.number}`),
            state: areas?.number,
        }
    }>
        <div className={'house-card'}>
            <div className={'card-img-wrapper'}>
                <img src={resizeImage(generateImageMediaUrl(areas?.mediaMain?.path))} alt=""/>
            </div>
            <div className={'card-badge'}>
                <span className={'price'}>{staticTexts?.house_card_price}</span>
                <span className={'sale'} style={{color: areas?.badge?.color}}>{areas?.badge?.title}</span>
            </div>
            {areas?.price ? <div className={'card-price'}>{priceWithCommas(areas?.price)} <DramIcon/></div>
                : <div className={'card-price'}>{staticTexts?.house_card_price_contractual}</div>}
            <div className={'areas-list'}>
                <div className={'list-item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_area_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{`${areas?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup></div>
                </div>
                <div className={'list-item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_floor_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{areas?.floor?.number}</div>
                </div>
                <div className={'list-item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_rooms_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{areas?.roomsCount || "-"}</div>
                </div>
            </div>
        </div>
    </Link>
}


const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SlidersCard);