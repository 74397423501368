// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import {LoadingOutlined} from '@ant-design/icons'
import {isEmail} from "validator";

// Import assets
import "./contact.scss"
import {
    ContactsAddress, PhoneDropDownIcon,
    TickIcon, ViberIcon, WhatsUppIcon,
} from "../../assets/images";

// Import Components
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";
import {YMaps, Map, Placemark, FullscreenControl} from "react-yandex-maps";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendMessage} from "../../redux/actions";
import AddressWrapper from "../../components/AddressWrapper/AddressWrapper";
import {RETROSHIN_LINK} from "../../constants/constants";
import {Dropdown, Menu} from "antd";


function Contact(props) {
    const [tick, setTick] = useState(false)
    const {requestLoading, contact, staticTexts, selectedLanguage} = props

    const phone = contact?.phoneNumber?.replace(/\D/g,'').substring(0,12)
    const areaCode = phone?.substring(0, 3);
    const code = phone?.substring(3,5);
    const content = phone?.substring(5, 7);
    const content1 = phone?.substring(7, 9);
    const content2 = phone?.substring(9, 12);

    const [fieldsData, setFieldsData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    })
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    })
    const showTick = () => {
        setTimeout(
            () => {
                setTick(false)
                setErrors({
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                })
                setFieldsData({
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                })
            },
            2000,
        )

    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false,
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email))
            ) {
                errors[key] = true;
                result = false;
            }
        })
        setErrors(errors);
        return result;
    }

    const sendMessage = () => {

        if (validate()) {

            props.SendMessage(fieldsData).then(() => {
                setTick(true)
                showTick()
            })
        }
    }

    const mapData = {
        center: [40.183609, 44.515196],
        zoom: 15,
    }

    const callMenu = (
        <Menu>
            <Menu.Item className={'callDropDown'}>
                <a href={`tel:+${phone}`} target={'_blank'}>
                    <PhoneDropDownIcon/>
                    <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                </a>
                <a href={`viber://chat?number=%2B${phone}`} target={'_blank'}>
                    <ViberIcon/>
                    <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                </a>
                <a href={`https://wa.me/+${phone}`} target={'_blank'}>
                    <WhatsUppIcon />
                    <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                </a>
            </Menu.Item>
        </Menu>
    );

    return <div className={'ContactWrapper'}>
        <AddressWrapper addressFirst ={staticTexts?. header_item_contact}/>
        <div className="ContactImageWrapper">
            <div className={'map-wrapper'}>
                <YMaps query={{
                    apikey: 'c5f70ecb-be1a-4260-9292-9ecafceda596',
                    lang: 'en'
                }} >
                    <Map defaultState={mapData} cursors={"inherit"} className={'yandexMap'} >
                        <Placemark
                            geometry={[40.183609, 44.515196]}
                            classname={'customIcon'}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref:RETROSHIN_LINK.iconUrl,
                                iconImageSize: [40,55],
                                iconImageOffset: [-15, -60],
                            }}
                        />
                        <FullscreenControl options={{
                            position:{
                                bottom:28,
                                right: 126,
                            },
                        }}/>
                    </Map>
                </YMaps>
            </div>
        </div>
        <div className={'ContactMainContent'}>
            <div className="LeftSide">
                <div className={'inside-block'}>
                    <div className={'subtitle'}>{staticTexts?.contacts_page_left_subTitle}</div>
                    <div className={'description'}>
                        <span><ContactsAddress/></span>
                        <p>{staticTexts?.contacts_page_address}</p>
                    </div>
                    <div className={'line'}/>
                    <Dropdown overlay={callMenu}
                              overlayClassName={''}
                              trigger={['click']}
                              className={'contact-items'}
                              placement="bottom"
                              size={'small'}>
                        <div className={'item-card-content-text'}>
                            {`+${areaCode} (${code}) ${content} ${content1} ${content2}`}
                        </div>
                    </Dropdown>
                    {/*<div className={'contact-items'}>*/}
                    {/*    <a href={`tel:${contact?.phoneNumber}`} className={'item-card'}>*/}
                    {/*        <div className={'item-card-content'}>*/}
                    {/*            <div className={'item-card-content-text'}>*/}
                    {/*                /!*{contact?.phoneNumber || contact?.phoneNumber2 || ""}*!/*/}
                    {/*                {`+${areaCode} (${code}) ${content} ${content1} ${content2}`}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="RightSide">
                <h1 className={'input-title'}>{staticTexts?.contacts_page_right_subTitle}</h1>
                <div className={'input-description'}>{staticTexts?.contacts_page_right_text}</div>
                <div className={'fields-wrapper'}>
                    <div className={'left-block'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.name}
                            error={errors.name}
                            name={'name'}
                            maxLength={30}
                            placeholder={staticTexts?.contacts_page_placeholder_name}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.email}
                            error={errors.email}
                            name={'email'}
                            maxLength={50}
                            placeholder={staticTexts?.contacts_page_placeholder_email}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.phone}
                            error={errors.phone}
                            name={'phone'}
                            maxLength={30}
                            placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                            onChange={getInputValues}
                        />
                    </div>
                    <div className={'right-block'}>
                        <InputGroup
                            inputType={'textarea'}
                            type={"text"}
                            value={fieldsData.message}
                            error={errors.message}
                            name={'message'}
                            placeholder={staticTexts?.contacts_page_placeholder_message}
                            onChange={getInputValues}
                        />
                        <button className='PrimaryButton'
                                onClick={() => {
                                    if (!requestLoading && sendMessage) {
                                        sendMessage();
                                    }
                                }}
                        >
                            {staticTexts?.contacts_page_btn_send_message}
                            {
                                requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                    : <TickIcon title={''} className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'requestLoading',
    'staticTexts',
    'about',
    'contact',
    'selectedLanguage'
]);

const mapDispatchToProps = {
    SendMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
