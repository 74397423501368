import { BUILDINGS_CONSTS } from "../constants";

export const initialState = {
    buildings: [],
    bestOffers:[],
    clear:false,
    filterState:null,
    buildingById: null,
    areas: {
        list: [],
        hasMore: false,
        buildingSlug: null,
        floors: [],
        roomsCount: 0,
        minArea: '',
        maxArea: '',
        minPrice: '',
        maxPrice: '',
        sort: 'price',
    }
};

const buildings = (state = initialState, action) => {

    switch ( action.type ) {
        case BUILDINGS_CONSTS.GET_BUILDINGS:
            return {
                ...state,
                buildings: action.payload,
            };
        case BUILDINGS_CONSTS.GET_BUILDING_BY_ID:
            return {
                ...state,
                buildingById: action.payload,
            };

        case BUILDINGS_CONSTS.GET_HOUSES:
            return {
                ...state,
                areas: {
                    list: action.payload.reset ? action.payload.data : [...state.areas.list, ...action.payload.data],
                    availableCount: action.payload.availableCount,
                    reservedCount: action.payload.reservedCount,
                    count: action.payload.count,
                    hasMore: action.payload.hasMore,
                    buildingSlug: action.payload.buildingSlug,
                    floors: action.payload.floors,
                    roomsCount: action.payload.roomsCount,
                    minArea: action.payload.minArea,
                    maxArea: action.payload.maxArea,
                    minPrice: action.payload.minPrice,
                    maxPrice: action.payload.maxPrice,
                    sort: action.payload.sort,
                    statuses: action.payload.statuses,
                    type:action.payload.type,
                }
            };
        case BUILDINGS_CONSTS.GET_BUILDING_BEST_OFFER:
            return {
                ...state,
                bestOffers: action.payload,
            };
        case BUILDINGS_CONSTS.CLEARS_FILTER:
            return {
                ...state,
                clear: action.payload,
            }
            case BUILDINGS_CONSTS.STATE_FILTER:
            return {
                ...state,
                filterState: action.payload,
            }

        default:
            return state;
    }
}; export default buildings
