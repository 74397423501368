import {priceWithCommas} from "../utils/helperFunctions";

export const ACCEPT_TYPES = ['.jpg', '.jpeg', '.png','.svg']; // '.gif', '.bpm',

export const ACCEPT_IMAGE_SIZE = 10485760; // 10mb
export const YOUTUBE_REGEXP= /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

export const MAX_FLOORS_COUNT = 18
export const MIN_PRICE = 350000;
export const MAX_PRICE = 100000000;

export const MIN_AREA = 37;
export const MAX_AREA = 285;

export const BUILDING_TYPE = {
    Apartments:'APARTMENT',
    Commerce:'OFFICE',
    Parking:'PARKING',
};

export const CURRENCY_VALUE = {
    USD_AMD:'USD',
    EUR_AMD:'EUR',
    RUB_AMD:'RUB',
}

export const ACCEPT_BADGE_TYPES = {
    FOR_SALE: "600ab6e5e503271f1c8ebcd0",
    RESERVED: "6012c054b88dc494783275c5",
    SOLD: "6012c054b88dc494783275c6"
};

export const LIMIT_MIN_PRICE = 30000000
export const LIMIT_MAX_PRICE = 35000000