// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import assets
// import './custom-pages.scss';

//Import Utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetCustomPages} from "../../redux/actions";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";

class CustomPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const slug = this.props?.match?.params?.slug;
        this.props.GetCustomPages(slug);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps?.match?.params?.slug !== this.props?.match?.params?.slug) {
            const slug = this.props?.match?.params?.slug;
            this.props.GetCustomPages(slug);
        }
    }

    render() {
        const {customPages} = this.props;
        return <div className="custom-page-wrapper">

            <div className="custom-page-content">
                {customPages?.mediaMain && <div className='left-part'>
                    <div className="blue-box"/>
                    <div className="LeftImgWrapper">
                        <div className="main-img-wrapper">
                            <div className="main-img-content">
                                <img src={generateFileMediaUrl(customPages?.mediaMain?.path)}
                                     alt=""
                                     className="custom-page-img main-img"/>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='right-part'>
                    {customPages?.title && <div className="custom-page-title">{customPages?.title}</div>}
                    {customPages?.content && <div dangerouslySetInnerHTML={{__html: customPages?.content}}/>}
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'customPages',
    ])
};

const mapDispatchToProps = {GetCustomPages};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);