import React, { useState } from "react";
import { Select } from "antd";
import "./inputGroup.scss";
import { SortArr, SortIcon} from "../../../assets/images";

//Import Images

const { Option } = Select;

export function InputGroup(props){
    const {
        label, type, inputType, name, className, placeholder, error, options, autocomplete = "on", onKeyDown, id,
        defValue, onChange, onSearch, value, maxLength, autoFocus, resizable, disabled, forwardRef,
        integerNumber, maxValue, minValue, sortIcon, dropdownRender
    } = props;
    const [selectOpen, toggleSelectVisibility] = useState(false);

    function onChangeNumber(e){
        let { name, value } = e.target;
        if ( disabled ) {
            return;
        }
        if ( value === '' ) {
            props.onChange({
                name,
                value,
            });
            return;
        }
        if ( isNaN(Number(value)) || value?.split('.')?.[1]?.length > 2 ) {
            return;
        }
        if ( value && integerNumber ) {
            value = parseInt(value)
        }
        if ( minValue || maxValue ) {
            if ( minValue && value < minValue ) {
                return;
            }
            if ( maxValue && value > maxValue ) {
                return;
            }
        }
        props.onChange({
            name,
            value,
        });
    }

    function onChangeSelect(value, option) {
        props.onChange(name, value, option);
    }

    return <div className="custom-input-group" id={name}>

        {label && <label>{label}</label>}
        {inputType === "input" && type !== "number" && type !== "checkbox" && type !== "password" &&
        <>
            <input type={type} name={name} placeholder={placeholder} maxLength={maxLength} ref={forwardRef}
                   value={value || ''} defaultValue={defValue} onChange={onChange} autoComplete={'off'}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} disabled={!!disabled} id={id}
                   className={`${className} ${error ? "invalid" : ""}`}/>
            {props.children}
        </>}

        {inputType === "input" && type === "password" &&
        <>
            <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                   value={value || ''} defaultValue={defValue} onChange={onChange}
                   autoFocus={autoFocus} autoComplete={autocomplete} onKeyDown={onKeyDown}
                   className={`${className} ${error ? "invalid" : ""}`}/>
            {props.children}
        </>}

        {inputType === "input" && type === "number" &&
        <input type={'text'} name={name} placeholder={placeholder} ref={forwardRef}
               value={value || ''} onChange={onChangeNumber} autoComplete={'off'}
               autoFocus={autoFocus} onKeyDown={onKeyDown} disabled={!!disabled} id={id}
               className={`${className} ${error ? "invalid" : ""}`}/>}

        {inputType === "input" && type === "checkbox" &&
        <input type={type} name={name} checked={value} onChange={onChange}/>}

        {inputType === "textarea" &&
        <textarea name={name} placeholder={placeholder} value={value || ''}
                  defaultValue={defValue} onChange={onChange} maxLength={maxLength}
                  className={`${className} ${error ? "invalid" : ""} ${resizable ? "resizable" : ""}`}/>}

        {inputType === "select" && props.onPopupScroll &&

        <Select
            value={value}
            showSearch={props.showSearch ?? true}
            defaultOpen={false}
            style={{ width: '100%' }}
            placeholder={placeholder}
            onPopupScroll={props.onPopupScroll}
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={false}
            getPopupContainer={() => document.getElementById(name)}
            dropdownRender={dropdownRender}
            onDropdownVisibleChange={(value) => toggleSelectVisibility(value)}
            suffixIcon={ sortIcon ? <SortIcon/> : <SortArr
                className={`custom-suffix-icon ${selectOpen ? 'rotate' : ''}`}
            />}
            className={`custom-select ${props.className || ''} ${error ? "invalid" : ""}`}
        >
            {!!value && props.onClear && <Option value={undefined}>{props.onClear}</Option>}
            {options && !!options.length && options.map((item, index) => {
                return <Option key={index}
                               value={item?.id}>{item?.name}</Option>
            })}
        </Select>
        }

        {inputType === "select" && !props.onPopupScroll &&
        // <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Select
            value={value}
            showSearch={props.showSearch ?? true}
            defaultOpen={false}
            showArrow={true}
            mode={type || 'default'}
            style={{ width: '100%' }}
            placeholder={placeholder}
            onChange={onChangeSelect}
            visible={true}
            dropdownRender={dropdownRender}
            onDropdownVisibleChange={(value) => toggleSelectVisibility(value)}
            suffixIcon={ sortIcon ? <SortIcon/> : <SortArr
                className={`custom-suffix-icon ${selectOpen ? 'rotate' : ''}`}
            />}
            notFoundContent={props.notFoundContent}
            optionFilterProp="children"
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={() => document.getElementById(name)}
            className={`custom-select ${props.className || ''} ${error ? "invalid" : ""}`}
        >
            {!!value && props.onClear && <Option value={undefined}>{props.onClear}</Option>}
            {options && !!options.length && options.map((item, index) => {
                return <Option key={index}
                               value={item?.id}
                               title={""}
                               className={item.child ? 'child-option' : ''}>
                    {item?.name}
                </Option>
            })}
        </Select>
            // </ConfigProvider>
        }
        {/*{inputType === "tree-select" &&*/}
        {/*// <ConfigProvider renderEmpty={customizeRenderEmpty}>*/}
        {/*<div className={'tree-select-wrapper'}>*/}
        {/*    <ArrowDownIcon*/}
        {/*        className={`tree-suffix-icon ${selectOpen ? 'rotate' : ''}`}*/}
        {/*    />*/}
        {/*    <TreeSelect*/}
        {/*        value={value}*/}
        {/*        onChange={onChange}*/}
        {/*        showSearch={false}*/}
        {/*        treeCheckable={true}*/}
        {/*        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}*/}
        {/*        showCheckedStrategy={props.showCheckedStrategy || 'SHOW_ALL'}*/}
        {/*        placeholder={placeholder}*/}
        {/*        onDropdownVisibleChange={(value) => toggleSelectVisibility(value)}*/}
        {/*        style={{width: '100%'}}*/}
        {/*        getPopupContainer={() => document.getElementById(name)}*/}
        {/*        className={`custom-select tree-select ${props.className || ''} ${error ? "invalid" : ""}`}*/}
        {/*        treeData={options}/>*/}
        {/*</div>*/}
        {/*    // </ConfigProvider>*/}
        {/*}*/}
        {inputType === "wrapper" &&
        <> {props.children}</>
        }
    </div>
}
