import React, {useEffect, useState} from "react";
import '../assets/styles/components/leftBlock.scss'
import {getPropsFromState} from "../redux/mapStateToProps";
import {ClearFilter, FilterState, GetHouses} from "../redux/actions";
import {connect} from "react-redux";
import {Slider} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {AREA_TYPES, ROOM_COUNTS} from "../constants/constants";
import {ClearIcon, Dram_icon} from "../assets/images";
import {
    BUILDING_TYPE,
    MAX_AREA,
    MAX_PRICE,
    MIN_AREA,
    MIN_PRICE
} from "../../src/constants/acceptedConsts";
import Currency from "./currency/Currency";
import {InputGroup} from "./ui-elements/input-group/inputGroup";
import {priceWithCommas} from "../utils/helperFunctions";
import {PrimaryButton} from "./ui-elements/buttons/Buttons";



function LeftBlock(props) {
    const {areas,clear,filters,filtersInitialValues,setFilters} = props
    const location = useLocation()
    const { staticTexts} = props
    const BUILDING_TYPE = {
        [`${staticTexts?.filter_apartment}`]:'APARTMENT',
        [`${staticTexts?.filter_commerce}`]:'OFFICE',
        [`${staticTexts?.filter_parking}`]:'PARKING',
    };
    const {
        list,
        count,
        sort,
        statuses
    } = areas || {}


    useEffect(() => {
        const {fromBuildings} = location?.state || {}
         if (fromBuildings) {
            const _filters = {}
            statuses && (_filters.statuses = statuses);
            props.GetHouses(_filters)
        }
    }, [])

    useEffect(() => {
        props.ClearFilter(false)
        setFilters(filtersInitialValues)
        return () => {
            changeFilter('clear', null)
        }
    }, [])


    function changeFilter(name, value) {
        let newFilters = {}
        if(value !== 'OFFICE'){
            if (name.includes('Price') || name.includes('Area')) {
                newFilters = {
                    ...filters,
                    ...value
                }
            }else {
                newFilters = {
                    ...filters,
                    [name]: value
                }
            }
            if (name === "clear") {
                newFilters = filtersInitialValues
            }
            if (name === "roomsCount") {
                newFilters = {
                    ...filters,
                    [name]: [value,...filters.roomsCount]
                }
                if(filters?.roomsCount.includes(value)){
                    newFilters = {
                        ...filters,
                        [name]:filters?.roomsCount?.filter(item => item !== value)
                    }
                }
            }

            // compareObjects(newFilters)
            if( name !== "minArea" && name !== "minPrice" && name !== "maxPrice" && name !== "maxArea"){
                setFilters(newFilters);
            }
            props.ClearFilter(true)
            props.GetHouses(newFilters)
            props.FilterState(newFilters)
        }
    }

    function compareObjects(newArray) {
        let visible = false
        Object.keys(filtersInitialValues).forEach(key => {
            if (Array.isArray(newArray[key])) {
                if (newArray[key]?.length !== filtersInitialValues[key]?.length) {
                    visible = true
                }
            } else {
                if (newArray[key] !== filtersInitialValues[key]) {
                    visible = true
                }
            }
        })
    }

    const onChangeSlider = (value, type) => {
        setFilters({
            ...filters,
            [`min${type}`]: value[0],
            [`max${type}`]: value[1],
        })
    }

    const onAfterChangePrice = (value,type) => {
        if(type === 'Area'){
            if(value[0] !== MIN_AREA || value[1] !== MAX_AREA){
                changeFilter(type, {
                    [`min${type}`]:value[0],
                    [`max${type}`]:value[1],
                })
            }
        }else{
            if(value[0] !== MIN_PRICE || value[1] !== MAX_PRICE){
                changeFilter(type, {
                    [`min${type}`]:value[0],
                    [`max${type}`]:value[1],
                })
            }
        }
    };

    const Options = [
        {name:2, id: '2'},
        {name:3, id: '3'},
        {name:4, id: '4'},
        {name:5, id: '5'},
        {name:6, id: '6'},
        {name:7, id: '7'},
        {name:8, id: '8'},
        {name:9, id: '9'},
        {name:10, id: '10'},
        {name:11, id: '11'},
        {name:12, id: '12'},
        {name:13, id: '13'},
        {name:14, id: '14'},
        {name:15, id: '15'},
        {name:16, id: '16'},
    ]
    return <div className={'leftBlock'}>
        <div className={'HousesFilters'}>
            <div className={'filters'}>
                <div className={'floor-wrapper'}>
                    {Object.keys(BUILDING_TYPE).map((index) => {
                        return <div key={index}
                                    className={`floor-block ${filters?.types === (BUILDING_TYPE[index]) ? 'active' : ''}`}
                                    onClick={() => {
                                        changeFilter('types', BUILDING_TYPE[index])
                                    }}
                        >{index}</div>
                    })}
                </div>
                <div className="filters-content">
                    <div className={'filter-top'}>
                        <div className={'roomsCount'}>
                            <div className={'block-title'}>{staticTexts?.filter_rooms_count_title}</div>
                            <div className={'rooms-count-content'}>
                                {ROOM_COUNTS.map((index) => {
                                    if(filters?.types === 'OFFICE' || filters?.types === 'PARKING'){
                                        return <button key={index}
                                                       disabled
                                                       className={`rooms-disabled-count-item`}
                                                       onClick={() => {
                                                           changeFilter('roomsCount', index)
                                                       }}
                                        >{index}</button>
                                    }else {
                                        return <button key={index}
                                                       className={`rooms-count-item ${filters?.roomsCount?.includes(index)  ? 'active' : ''}`}
                                                       onClick={() => {
                                                           changeFilter('roomsCount', index)
                                                       }}
                                        >{index}</button>
                                    }
                                })}
                            </div>
                        </div>
                        <div className={'slider-filter area'}>
                            <div className={'block-title'}>{staticTexts?.filter_area_title}</div>
                            <div className={'border-block'}>
                            <div className={'slider-filter-top'}>
                               <div className={'left-block'}>
                                   <span>{staticTexts?.filter_from}</span>
                                   <div className={'filters-area'}>{filters.minArea}<p>{staticTexts?.measurement_unit_meter}<sup>2</sup></p></div>
                               </div>
                                <div className={'right-block'}>
                                    <span>{staticTexts?.filter_to}</span>
                                    <div className={'filters-area'}>{filters.maxArea}<p>{staticTexts?.measurement_unit_meter}<sup>2</sup></p></div>
                                </div>
                            </div>
                            <Slider range
                                    min={MIN_AREA}
                                    max={MAX_AREA}
                                    value={[filters.minArea, filters.maxArea]}
                                    tipFormatter={null}
                                    onChange={(value) => onChangeSlider(value, 'Area')}
                                    onAfterChange={(value) => onAfterChangePrice(value,'Area')}
                            />
                            </div>
                        </div>
                        <div className={'slider-filter'}>
                            <div className={'block-title'}>{staticTexts?.filter_price_title}</div>
                            <div className={'border-block'}>
                            <div className={'slider-filter-top'}>
                                <div className={'left-block'}>
                                    <span>{staticTexts?.filter_from}</span>
                                    <div className={'filters-area'}>{priceWithCommas(filters.minPrice)}<Dram_icon/></div>
                                </div>
                                <div className={'right-block'}>
                                    <span>{staticTexts?.filter_to}</span>
                                    <div className={'filters-area'}>{priceWithCommas(filters.maxPrice)}<Dram_icon/></div>
                                </div>
                            </div>
                            <Slider range
                                    min={MIN_PRICE}
                                    max={MAX_PRICE}
                                    value={[filters.minPrice, filters.maxPrice]}
                                    tipFormatter={null}
                                    onChange={(value) => onChangeSlider(value, 'Price')}
                                    onAfterChange={(value) => onAfterChangePrice(value,'Price')}
                            />
                            </div>
                        </div>
                        <div className={'slider-filter'}>
                            <div className={'floors-select'}>
                                <div className={'block-title'}>{staticTexts?.filter_floors_title}</div>
                                {filters?.types === 'PARKING' ?
                                    <div className={'disable-input'}>
                                        <InputGroup
                                            showSearch={false}
                                            inputType={'input'}
                                            value={''}
                                            name={'floors'}
                                            disabled={true}
                                            placeholder={staticTexts?.filter_floors_title}
                                            onChange={changeFilter}
                                            options={Options}/>
                                    </div> :
                                    <InputGroup
                                        showSearch={false}
                                        inputType={'select'}
                                        value={filters.floors}
                                        name={'floors'}
                                        placeholder={staticTexts?.filter_floors_title}
                                        onChange={changeFilter}
                                        options={Options}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'filter-down'}>
                        {clear && <button onClick={() => {
                            changeFilter('clear', null)
                            props.ClearFilter(false)
                        }}>{staticTexts?.filter_clear_filters} <ClearIcon title={''}/></button>}
                    </div>
                </div>
                <PrimaryButton className="filters-count-wrapper"
                               title={<div className="filters-count">
                                   {count || 0} <span>{staticTexts?.filter_rooms_total}</span></div>}
                               cb={props.toggleMobileFilter}
                               link={'/filter'}
                >
                </PrimaryButton>
            </div>
            <Currency/>
        </div>
    </div>
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'areas',
    'badges',
    'buildings',
    'clear',
]);

const mapDispatchToProps = {
    GetHouses,
    ClearFilter,
    FilterState,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftBlock)