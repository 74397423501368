// import packages
import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import Slider from "react-slick";
import {Breadcrumb, Popover, Radio,} from 'antd';
import {history} from "../../configs/history";
import {useLocation, useParams} from 'react-router-dom';
import {Link} from "react-router-dom";
import  Amiri from "../../assets/fonts/arial.ttf"

// Import assets
import "./house-details.scss"
import defaultFileLogo from '../../assets/images/default.png';
import {
    CompassImage,
    LogoFooter,
    ArrowLeftIcon,
    ArrowRightIcon,
    ParkingIcon, LocationIcon, Share_Icon, Print_Icon,
} from "../../assets/images";

// Import Components
import {PrimaryButton} from "../../components/ui-elements/buttons/Buttons";
import ReserveModal from "../../components/modal/ReserveModal";
import {ImagesSlider} from "../../components/ui-elements/imagesZoomSlider/ImagesSlider";
import MetaTags from "../../components/MetaTags";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetHouseById, GetParkingById, ReserveHouse, GetBuildingByIdOrSlug} from "../../redux/actions";
import {HOUSE_BADGES, RETROSHIN_LINK} from "../../constants/constants";
import {generateFileMediaUrl, generateFileMediaUrlWithoutDefault} from "../../utils/generateMediaUrl";
import {priceWithCommas} from "../../utils/helperFunctions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import SimilarApartmentSlider from "../../components/SimilarApartmentSlider";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {jsPDF} from "jspdf";


function HouseDetails(props) {
    const componentRef = useRef();
    const [house, setHouse] = useState({})
    const [imagesSliderOpen, setImagesSliderOpen] = useState(false)
    const [modalCurrentImageIndex, setModalCurrentImageIndex] = useState(0)
    const [modalImagePathList, setModalImagePathList] = useState([])
    const [loading, setLoading] = useState("loading")
    const params = useParams()
    const [visible, setVisible] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState("");
    const floorNumber = house?.floor?.number
    const {staticTexts, buildingById, badges} = props
    const [slideIndex, setSlideIndex] = useState(0);
    const [otherProjectsSlideIndex, setOtherProjectsSlideIndex] = useState(0);
    let sliderRef = useRef(null);
    let otherProjectsSliderRef = useRef(null);
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [house])

    useEffect( () => {
        setLoading("loading")
        const request = props.GetHouseById
       params?.house && request(params?.house).then((data) => {
            setHouse(data);
            !data?.medias?.length ? setLoading("failed") : setLoading("loaded")
        }).catch(() => {
            history.push(getUrlWithLocal("/"))
        })

    }, [location?.state])

    useEffect(() => {
        (loading === "loaded" || loading === "failed") && (document.getElementById("compassImg") && (document.getElementById("compassImg").style.transform = `rotate(${house.compassAngle}deg)`))
    }, [loading])
    const nextSlide = (index, setState, ref) => {
        setState(index + 1)
        if (modalImagePathList?.length - 1 === index) {
            setState(index)
        }
        ref.slickNext();
    };

    const prevSlide = (index, setState, ref) => {
        setState(index === 0 ? 0 : index - 1)
        ref.slickPrev();
    };

    const toggleVisibility = () => {
        setVisible(!visible)
    }

    const openImagesSlider = (modalImagePathList, imageIndex) => {
        setImagesSliderOpen(true)
        setModalCurrentImageIndex(imageIndex);
        setModalImagePathList(modalImagePathList);
    }

    const closeImagesSlider = () => {
        setImagesSliderOpen(false)
        setTimeout(() => {
            setModalImagePathList([])
            setModalCurrentImageIndex(0)
        }, 150)
    }

    const selectCheckbox = (e) => {
        setCheckboxValue(e.target.value)
    }
    const onClickFloor = (floor, num) => {
        history.push(getUrlWithLocal(`/floor/${floor}/place/${num}`))
    }

    const settings = {
        dots: false,
        arrows: false,
        swipeToSlide: false,
        focusOnSelect: false,
        infinite: false,
        slidesToShow: 4,
    };


    const content = (
        <div className={'social-media'}>
            <FacebookShareButton url={`${RETROSHIN_LINK.url}/floor/${params?.num}/place/${house?.id}`} quote={"Retroshin"}>
                <p><FacebookIcon size={18} round={true}/> Facebook</p>
            </FacebookShareButton>
            <TwitterShareButton url={`${RETROSHIN_LINK.url}/floor/${params?.num}/place/${house?.id}`} title={'Retroshin'} >
                <p><TwitterIcon size={18} round={true}/> Twitter</p>
            </TwitterShareButton>
            <LinkedinShareButton url={`${RETROSHIN_LINK.url}/floor/${params?.num}/place/${house?.id}`} title={'Retroshin'} description={'Hello'}>
                <p><LinkedinIcon size={18} round={true}/> Linkedin</p>
            </LinkedinShareButton>
        </div>
    );

    function jsPdfGenerator (){
        let doc = new jsPDF('p', 'pt', 'a4');
        doc.addFont(`${Amiri}`, "Amiri", "normal");
        doc.setFont("Amiri");
        doc.setFontSize(16);
        doc.text(`${staticTexts?.house_card_name}`,40,160)
        doc.setFontSize(14);
        doc.text(`${house?.floor?.number > 1 ? `${staticTexts?.house_right_info_title} N ${house?.areaNumber}`
            : `${staticTexts?.rout_item_title_parking} N ${house?.areaNumber}`}`,40,540,)
        doc.setFontSize(12);
        doc.setTextColor(`${house?.badge?.color}`);
        doc.text(`${house?.badge?.title}`,40,560,)
        doc.setTextColor(`black`);
        doc.text(`${staticTexts?.house_card_area_title} ${house?.area} ${staticTexts?.measurement_unit_meter} 2`,40,580,)
        doc.text(`${house?.price ? `${priceWithCommas(house?.price)} AMD`
                                    : `${staticTexts?.house_card_price_contractual}`}`,40,610)
        doc.text(`+374(11) 420 270`,440,640)
        doc.text(`${staticTexts?.footer_section_address}`,390,660)
        doc.setTextColor(`#CDBB99`);
        doc.text(`${RETROSHIN_LINK.url}`,360,680,)
        doc.html(document.querySelector("#result-pdf"),{
            callback: function (doc){
                if(window.innerWidth < 1001){
                   doc.save('file.pdf')
                }else{
                    doc.output('dataurlnewwindow', 'file.pdf');
                }
            }
        })
    }

    return <div className={'HouseWrapper'}>
        <MetaTags title={`${floorNumber > 0 ? staticTexts?.rout_item_house : staticTexts?.rout_item_title_parking} N ${house?.areaNumber}`}/>
        <div className={'ContentWrapper'}>
            <div className={'AddressWrapper'}>
                <div className={'address-left-wrapper'}>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link
                            to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                        {house?.floor?.number && <Breadcrumb.Item>
                            <Link to={getUrlWithLocal(`/floor/${house?.floor?.number}`)}>
                                {`${staticTexts?.rout_item_title_floor} ${house?.floor?.number}`}
                            </Link>
                        </Breadcrumb.Item>}
                        {house?.floor?.number > 1 &&
                            <Breadcrumb.Item>{house?.floor?.number > 1 ? `${staticTexts?.rout_item_house} N ${house?.areaNumber}`
                                : `${staticTexts?.rout_item_office} N ${house?.areaNumber}`}</Breadcrumb.Item>}
                        {house?.floor?.number < 1 &&
                            <Breadcrumb.Item>{`${staticTexts?.rout_item_title_parking} N ${house?.areaNumber}`}</Breadcrumb.Item>}
                    </Breadcrumb>
                </div>
                <div className={'address-right-wrapper'}>
                    <Link to={getUrlWithLocal('/floor/-1')} className={'item-card'}>
                        <div className={'parking-icon-wrapper'}>
                            <ParkingIcon />
                        </div>
                    </Link>
                    <Link to={getUrlWithLocal('/address')} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <LocationIcon/>
                        </div>
                    </Link>
                </div>
            </div>
            <div className={'DistributeWrapper'}>
                <div className={'share-block'}>
                    <div className={'share'}>
                        <Popover content={content} trigger="click" placement="bottom">
                            <Share_Icon/>
                            <span>{staticTexts?.share_title}</span>
                        </Popover>
                    </div>
                    <div className={'line'}/>
                    <div className={'print'} onClick={jsPdfGenerator} type="primary"><Print_Icon/><span>{staticTexts?.print_title}</span></div>
                </div>
            </div>
            <div className={"FloorWrapper"}>
                <div className={"FloorMainContent"}>
                    <div className={'LeftSide'} >
                        <div className={'left-side-slider'}>
                            {loading === "loaded" &&
                            <div className="slider-wrapper">
                                <div className="main-img-wrapper">
                                    <div className="main-img-content" ref={componentRef}>
                                        <img
                                            src={generateFileMediaUrlWithoutDefault(house?.medias?.[slideIndex]?.path)}
                                            className={'main-img'}
                                            onClick={() => openImagesSlider(house?.medias, slideIndex)} alt={""}
                                        />
                                    </div>
                                </div>
                                {/*{loading}*/}
                                <div className="images-list-wrapper">
                                    {!!house?.medias?.length &&
                                    <Slider {...settings} ref={slider => (sliderRef = slider)}>
                                        {house?.medias?.map((item, index) => {
                                            return <div key={item.id}
                                                        className={`list-item ${(slideIndex === index) ? 'active' : ''}`}>
                                                <img
                                                    src={generateFileMediaUrl(item?.path)} className={'list-img'}
                                                    onClick={() => setSlideIndex(index)} alt={"about"}/>
                                            </div>
                                        })}
                                    </Slider>}
                                    {house?.medias?.length > 5 &&
                                    <>
                                        <button className={`slider-btn  prev-btn`}
                                                onClick={() => prevSlide(slideIndex, setSlideIndex, sliderRef)}
                                                disabled={slideIndex === 0}
                                        >
                                            <ArrowLeftIcon/>
                                        </button>
                                        <button
                                            className={`slider-btn next-btn`}
                                            disabled={slideIndex === house?.medias?.length - 1}
                                            onClick={() => nextSlide(slideIndex, setSlideIndex, sliderRef)}>
                                            <ArrowRightIcon/>
                                        </button>
                                    </>
                                    }
                                </div>
                            </div>}
                            {loading === "loading" && <div className={'image-loader'}/>}
                            {loading === "failed" && <div className="slider-wrapper">
                                <div className="main-img-wrapper">
                                    <div className="main-img-content">
                                        <img
                                            src={defaultFileLogo}
                                            className={'main-img'}
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className={'compass-wrapper compass-wrapper-in-left '}>
                                <img id="compassImg" src={CompassImage} alt=""/>
                            </div>
                        </div>
                        {house?.floor?.number > 2 && <div className={'checkboxes-wrapper'}>
                            <Radio.Group value={checkboxValue} onChange={selectCheckbox}>
                                {house?.projects?.length && <Radio className={'house-details-checkbox'}
                                                                   value={"other-projects"}>{staticTexts?.house_checkbox_other_projects}</Radio>}
                                {house?.identicalHouses?.length && <Radio className={'house-details-checkbox'}
                                                                          value={"identical-houses"}>{staticTexts?.house_checkbox_identical_houses}</Radio>}
                            </Radio.Group>
                            {checkboxValue === "other-projects" &&
                            <div className={'left-side-slider'}>
                                <div className={'slider-wrapper other-projects-images-list'}>
                                    <div className="images-list-wrapper">
                                        {!!house?.projects?.length &&
                                        <Slider {...settings} ref={slider => (otherProjectsSliderRef = slider)}
                                                slidesToShow={3}>
                                            {house?.projects?.map((item, index) => {
                                                return <div key={item.id}
                                                            className={`list-item`}>
                                                    <img
                                                        src={generateFileMediaUrl(item?.path)} className={'list-img'}
                                                        alt={"about"} onClick={() => {
                                                        openImagesSlider(house?.projects, index)
                                                    }}/>
                                                </div>
                                            })}
                                        </Slider>}
                                        {house?.projects?.length > 3 &&
                                        <>
                                            <button className={`slider-btn prev-btn`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        prevSlide(otherProjectsSlideIndex, setOtherProjectsSlideIndex, otherProjectsSliderRef)
                                                    }}
                                                    disabled={otherProjectsSlideIndex === 0}
                                            >
                                                <ArrowLeftIcon/>
                                            </button>
                                            <button
                                                className={`slider-btn next-btn`}
                                                disabled={otherProjectsSlideIndex === house?.projects?.length - 1}
                                                onClick={() => {
                                                    nextSlide(otherProjectsSlideIndex, setOtherProjectsSlideIndex, otherProjectsSliderRef)
                                                }}>
                                                <ArrowRightIcon/>
                                            </button>
                                        </>}
                                    </div>
                                </div>
                            </div>
                            }
                            {checkboxValue === "identical-houses" &&
                            <div>
                                <div className="identical-houses-list">
                                    {buildingById && !!buildingById?.floors?.length && buildingById?.floors?.slice(2).map(item => {
                                        let identicalHouse = house?.identicalHouses.find(h => h?.floor?.id === item?.id)
                                        let badge = identicalHouse?.id && badges?.find(badge => badge?.id === identicalHouse?.badge)
                                        return <div
                                            onClick={() => identicalHouse?.id && onClickFloor(item?.number, identicalHouse?.number)}
                                            style={badge && {
                                                background: badge?.color,
                                                color: "white",
                                                cursor: "pointer"
                                            }}
                                            key={item?.id}
                                            className={`list-item ${item.number === house?.floor?.number ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}</div>
                                    })}
                                </div>
                                <div className={'badges-wrapper'}>
                                    {badges && !!badges?.length && badges.map(item => {
                                        return <div className={'badge-item'} key={item?.id}>
                                            <div className={'badge-color'} style={{background: item.color}}></div>
                                            <div>{item?.title}</div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            }
                        </div>}
                    </div>
                    <div className={'RightSide'}>
                        {house?.floor?.number > 1 &&
                            <div className={'header-title'}>{house?.floor?.number > 1 ? `${staticTexts?.house_right_info_title} N ${house?.areaNumber}`
                                : `${staticTexts?.rout_item_title_office} N ${house?.areaNumber}`}</div>}
                        {house?.floor?.number < 1 &&
                            <div className={'header-title'}>{house?.floor?.number < 1 && `${staticTexts?.rout_item_title_parking} N ${house?.areaNumber}`}</div>
                        }
                         <div className={'upload-pdf-wrapper'}>
                            <div className={'house-badge'} style={{color: house?.badge?.color}}>
                                {house?.badge?.title}
                            </div>
                        </div>
                        <div className={'areas-list'}>
                             <div className={'list-item'}>
                                <div className={'item-name'}>{staticTexts?.house_card_area_title}</div>
                                <div className={'line'}/>
                                <div
                                    className={'item-area'}>{`${house?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup>
                                </div>
                            </div>
                            {house?.rooms && !!house?.rooms?.length && house?.rooms.map(item => {
                                return <div className={'list-item'} key={item?.id}>
                                    <div className={'item-name'}>{item?.title}</div>
                                    <div className={'line'}/>
                                    <div
                                        className={'item-area'}>{`${item?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="linkWrapper">
                            {/*<div className={'switch-wrapper'}>*/}
                            {/*    <span>{staticTexts?.filter_repair_title}</span>*/}
                            {/*    <Switch checked={renovatedValue} onClick={changeRenovatedValue}*/}
                            {/*            className={"house-details-checkbox"}>{staticTexts?.house_right_info_renovated_checkbox}</Switch>*/}
                            {/*</div>*/}
                            <div className={'price-block'}>
                                {house?.price ? <div className={'card-price'}>{priceWithCommas(house?.price)} AMD</div>
                                    : <div className={'card-price'}>{staticTexts?.house_card_price_contractual}</div>}
                            </div>
                           <div className={"monthly-price-wrapper"}>
                               <Link to={{
                                       pathname: getUrlWithLocal('/calculator'),
                                       state: house?.price
                                   }}>
                                   <span>{staticTexts?.house_right_info_monthly_payment}</span>
                               </Link>
                            </div>
                        </div>
                        <div className={'price-wrapper'}>
                            <PrimaryButton disabled={house?.badge?.id !== HOUSE_BADGES?.available?.key}
                                           title={staticTexts?.house_right_info_reserve_now_btn} cb={toggleVisibility}/>
                            <ReserveModal house={house} visible={visible} toggleVisibility={toggleVisibility}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={'similar-wrapper'}>
            {house?.identicalAreas?.length > 0 && <SimilarApartmentSlider areas={house?.identicalAreas}/>}
        </div>

        <div className={'pdfBlock'} id={'result-pdf'}>
            <div className={'pdf-logo'}>
                <img src={LogoFooter} alt=""/>
            </div>
            <div className={'pdf-img'}>
                    <img
                        src={generateFileMediaUrlWithoutDefault(house?.medias?.[slideIndex]?.path)}
                        className={'main-img'}
                        onClick={() => openImagesSlider(house?.medias, slideIndex)} alt={""}
                    />
            </div>
        </div>
        <ImagesSlider visible={imagesSliderOpen}
                      title={`${house?.floor?.number > 0 ? staticTexts?.rout_item_house : staticTexts?.rout_item_title_parking} N ${house?.areaNumber || house?.parkingNumber}`}
                      modalImagePathList={modalImagePathList}
                      modalCurrentImageIndex={modalCurrentImageIndex}
                      closeModal={closeImagesSlider}/>
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'selectedLanguage',
    'renovatedPrice',
    'banksList',
    'buildingById',
    'badges',
]);

const mapDispatchToProps = {
    GetHouseById,
    GetParkingById,
    ReserveHouse,
    GetBuildingByIdOrSlug
};

export default connect(mapStateToProps, mapDispatchToProps)(HouseDetails);