import {
    BuildingAFloor1, BuildingAFloor10,
    BuildingAFloor10Select, BuildingAFloor11,
    BuildingAFloor11Select, BuildingAFloor12,
    BuildingAFloor12Select, BuildingAFloor13,
    BuildingAFloor13Select, BuildingAFloor14,
    BuildingAFloor14Select, BuildingAFloor15,
    BuildingAFloor15Select, BuildingAFloor16,
    BuildingAFloor16Select,
    BuildingAFloor1Select,
    BuildingAFloor2,
    BuildingAFloor2Select, BuildingAFloor3,
    BuildingAFloor3Select, BuildingAFloor4,
    BuildingAFloor4Select, BuildingAFloor5,
    BuildingAFloor5Select, BuildingAFloor6,
    BuildingAFloor6Select, BuildingAFloor7,
    BuildingAFloor7Select, BuildingAFloor8,
    BuildingAFloor8Select, BuildingAFloor9,
    BuildingAFloor9Select, BuildingAParking1, BuildingAParking1Select, BuildingAParking2, BuildingAParking2Select,
     sold_en, sold_hy, sold_ru,

} from "../assets/images";

export const BUILDINGS_KEYS = {
    A: "A1",
}
export const floorsImages = {
    "A1-1": BuildingAParking1,
    "A1-2": BuildingAParking2,
    A11: BuildingAFloor1,
    A12: BuildingAFloor2,
    A13: BuildingAFloor3,
    A14: BuildingAFloor4,
    A15: BuildingAFloor5,
    A16: BuildingAFloor6,
    A17: BuildingAFloor7,
    A18: BuildingAFloor8,
    A19: BuildingAFloor9,
    A110: BuildingAFloor10,
    A111: BuildingAFloor11,
    A112: BuildingAFloor12,
    A113: BuildingAFloor13,
    A114: BuildingAFloor14,
    A115: BuildingAFloor15,
    A116: BuildingAFloor16,
}
export const floorsSelect = {
    "A1-1": BuildingAParking1Select,
    "A1-2": BuildingAParking2Select,
    A11: BuildingAFloor1Select,
    A12: BuildingAFloor2Select,
    A13: BuildingAFloor3Select,
    A14: BuildingAFloor4Select,
    A15: BuildingAFloor5Select,
    A16: BuildingAFloor6Select,
    A17: BuildingAFloor7Select,
    A18: BuildingAFloor8Select,
    A19: BuildingAFloor9Select,
    A110: BuildingAFloor10Select,
    A111: BuildingAFloor11Select,
    A112: BuildingAFloor12Select,
    A113: BuildingAFloor13Select,
    A114: BuildingAFloor14Select,
    A115: BuildingAFloor15Select,
    A116: BuildingAFloor16Select,
}
export const PARKING_COUNTS = {
    '-2': "632c38fec95f5dc8f98f3463",
    '-1': "632c38fec95f5dc8f98f3462",
};

export const HYPOTHEC_TERM_TYPES = {
    MONTH: 'calculator_hypothec_type_mount',
    YEAR: 'calculator_hypothec_type_year',
}

export const REPAYMENT_TYPES = {
    ANNUITY: 'calculator_repayment_type_annuity',
    DIFFERENTIATED: 'calculator_repayment_type_differentiated',
}

export const DEFAULT_ADDRESS = {
    lat: 40.208370,
    lng: 44.525650
};
export const MAP_DEFAULT_ZOOM = 15;


export const HOUSE_BADGES = {
    fixed: {
        key: "6012c054b88dc494783275c5",
        title: "Ամրագրված է"
    },
    sold: {
        key: "6012c054b88dc494783275c6",
        title: "Վաճառված է",
    },
    available: {
        key: "600ab6e5e503271f1c8ebcd0",
        title: "Առկա է"
    },
};

export const SOLD_IMAGES = {
    hy: sold_hy,
    ru: sold_ru,
    en: sold_en,
};

export const ROOM_COUNTS = [1,2,3,4];

export const RESERVE_MODAL_TITLE_TYPES = {
    success: "success",
    error: "error",
    title: "title",
}

export const PROJECT_ID = {
    id: "628385aa0a9d6332a0f4e80e"
}
export const RETROSHIN_LINK = {
    iconUrl:'https://api.retroshin.am/admin/files/For-map/Component-7.svg',
    url: "https://retroshin.am"
}
export const CURRENCY = ['USD_AMD', 'EUR_AMD', 'RUB_AMD']

export const AREA_TYPES = {
    APARTMENT: {
        title: 'area_type_apartment',
        key: 'APARTMENT',
    },
    OFFICE: {
        title: 'area_type_office',
        key: 'OFFICE',
    },
    /*  HOTEL:{
          title: 'area_type_hotel',
          key: 'HOTEL',
      },*/
    PARKING:{
        title: 'area_type_perking',
        key: 'PARKING',
    },
};


