// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useLocation} from 'react-router-dom';

// Import assets
import '../assets/styles/components/footer.scss'
import {
    CompanyLogo,
    FooterFbIcon,
    FooterInctIcon,
    FooterPhoneNumberIcon,
    FooterTelephonePhoneIcon,
    FooterVector, PhoneDropDownIcon, ViberIcon, WhatsUppIcon,
} from "../assets/images";

// Import utils
import {Dropdown} from "antd";

import {getPropsFromState} from "../redux/mapStateToProps";
import {AddSubscribers} from "../redux/actions";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {isEmail} from "validator";
import {InputGroup} from "./ui-elements/input-group/inputGroup";
import {Menu} from "antd";

function Footer(props) {

    const {requestLoading,contact, staticTexts} = props
    const [email, setEmail] = useState('')
    const [errorEmail, setErrorEmail] = useState(false)
    const location = useLocation();

    useEffect(()=>{
        setErrorEmail(false)
        setEmail('')
    },[location.pathname])

    const showTick = () => {
        setTimeout(
            () => {
                setEmail("")
                setErrorEmail(false)
            },
            2000,
        )

    }

    function getInputValues(e) {
        setErrorEmail(false)
        const {value} = e.target;
        setEmail(value)
    }

    function addSubscribers() {
        if (email && isEmail(email)) {
            props.AddSubscribers({email: email}).then(() => {
                setEmail(email)
                showTick()
            }).catch((err) => {
                if(err.response?.data?.slug){
                    setErrorEmail(true)
                }
            })
        } else {
            setErrorEmail(true)
        }
    }


    const phone = contact?.phoneNumber?.replace(/\D/g,'').substring(0,12)
    const areaCode = phone?.substring(0, 3);
    const code = phone?.substring(3,5);
    const content = phone?.substring(5, 7);
    const content1 = phone?.substring(7, 9);
    const content2 = phone?.substring(9, 12);

    const callMenu = (
        <Menu>
            <Menu.Item className={'callDropDown'}>
                <a href={`tel:+${phone}`} target={'_blank'}>
                    <PhoneDropDownIcon/>
                    <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                </a>
                <a href={`viber://chat?number=%2B${phone}`} target={'_blank'}>
                    <ViberIcon/>
                    <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                </a>
                <a href={`https://wa.me/+${phone}`} target={'_blank'}>
                    <WhatsUppIcon />
                    <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                </a>
            </Menu.Item>
        </Menu>
    );

    return <footer className='footerWrapper'>
        <div className={'topFooterBlock'}>
            <div className={'topFirstBlock'}>
                <span className={'BlocksTitle'}>{staticTexts?.footer_section_office_title}</span>
                <span className={'Address'}>{staticTexts?.footer_section_office_address}</span>
            </div>
            <div className={'questionsBlock'}>
                <span className={'questionsTitle'}>{staticTexts?.footer_section_questions}</span>
                <span className={'number'}>
                    <Dropdown overlay={callMenu}
                              overlayClassName={''}
                              trigger={['click']}
                              placement="bottom"
                              size={'small'}>
                    <p>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</p>
                </Dropdown>
                </span>
            </div>
        </div>
        <div className="top-side desktop">
            <div className={'mobile-first-block'}>
                <div className="col">
                    <Link to={getUrlWithLocal(`/buildings`)}>
                        <p>{staticTexts?.about_page_count_projects}</p>
                    </Link>
                    <Link to={getUrlWithLocal(`/about`)}>
                        <p>{staticTexts?.footer_section_about}</p>
                    </Link>
                    <Link to={getUrlWithLocal(`/filter`)}>
                        <p>{staticTexts?.homepage_about_button_houses}</p>
                    </Link>
                    <Link to={getUrlWithLocal(`/calculator`)}>
                        <p>{staticTexts?.homepage_slider_calculator_hypothec}</p>
                    </Link>
                    <Link to={getUrlWithLocal(`/about`)}>
                        <p>{staticTexts?.footer_section_about}</p>
                    </Link>
                    {window.innerWidth > 1000 &&
                        <Link to={getUrlWithLocal(`/contact`)}>
                        <p>{staticTexts?.header_item_contact}</p>
                    </Link>}
                </div>
                <div className="col">
                    <div>{staticTexts?.header_item_info}</div>
                    <div className="social-wrapper">
                        <a href={contact?.facebookUrl} target={'_blank'} rel="noreferrer"><FooterFbIcon title=''/></a>
                        <a href={contact?.instagramUrl} target={'_blank'} rel="noreferrer"><FooterInctIcon title=''/></a>
                    </div>
                </div>
            </div>
            <div className={'mobile-second-block'}>
                <div className="col">
                    <div className={'title'}>{staticTexts?.homepage_contact_title}</div>
                    <div className={`subscribeBlock ${errorEmail ? 'invalid' : ''}`}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={email}
                            error={errorEmail}
                            name={'email'}
                            placeholder={staticTexts?.homepage_contact_placeholder_email}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <button className={'subscribeBtn'}
                                onClick={() => {
                                    if (!requestLoading && addSubscribers) {
                                        addSubscribers();
                                    }
                                }}
                        >
                            <FooterVector/>
                        </button>
                    </div>
                </div>
                <div className="col">
                    <a href={`mailto:${contact?.email}`}>
                        <p>{contact?.email}</p>
                    </a>
                    <Dropdown overlay={callMenu}
                              overlayClassName={''}
                              trigger={['click']}
                              placement="bottom"
                              size={'small'}>
                        <p className={'dropDownTelephone'}>
                            <FooterTelephonePhoneIcon/>
                        <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                        </p>
                    </Dropdown>
                    <a href={`tel:${contact?.phoneNumber2}`} className={'telephones'}>
                        <FooterPhoneNumberIcon/>
                        <p>{`${contact?.phoneNumber2}`}</p>
                    </a>
                        <p>{staticTexts?.footer_section_address}</p>
                        <p>{staticTexts?.footer_section_address2}</p>
                </div>
            </div>
            <div className={'social-block'}>
                <div className="col">
                        <div>{staticTexts?.header_item_info}</div>
                    <div className="social-wrapper">
                        <a href={contact?.facebookUrl} target={'_blank'} rel="noreferrer"><FooterFbIcon title=''/></a>
                        <a href={contact?.instagramUrl} target={'_blank'} rel="noreferrer"><FooterInctIcon title=''/></a>
                    </div>
                </div>
            </div>
        </div>
        <div className={'privacy-policy'}>
            <Link to={getUrlWithLocal(`/privacy-policy`)}>
                <p>{staticTexts?.footer_section_privacy}</p>
            </Link>
        </div>
        <div className='developed-by'>{staticTexts?.footer_section_developed_by_text}
            <a href="https://4steps.am/" target="_blank" rel="noreferrer"><CompanyLogo/></a>
        </div>
        <div className="bottom-side">
            <div>{staticTexts?.footer_section_copyright_text}</div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'contact',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    AddSubscribers
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
