import {_urlAbout, request} from "../api";
import {ABOUT_CONSTS} from "../constants";

export const GetAbout = () => {
    const requestData = {
        url: `${_urlAbout}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: ABOUT_CONSTS.GET_ABOUT,
                    payload: data
                })
            })
    };
};
