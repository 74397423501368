// Import packages
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

// Import styles
import "./house-card.scss";

import {DramIcon, UploadIcon} from "../../assets/images";
// Import Components
import ReserveModal from "../modal/ReserveModal";

import {PrimaryButton} from "../ui-elements/buttons/Buttons";
// Import utils
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {HOUSE_BADGES} from "../../constants/constants";
import {priceWithCommas} from "../../utils/helperFunctions";
import {resizeImage} from "../../utils/resizeImage";
import {Dropdown, Menu} from "antd";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";


function HouseCard(props) {
    const [visible, setVisible] = useState(false);
    const {house, staticTexts} = props
    const toggleVisibility = () => {
        setVisible(!visible)
    }
    function preventClick(e){
        e.preventDefault();
        e.stopPropagation()
    }

    function downloadFile(id) {
        const pdf = house?.pdfs?.find(item => item?.id === id)
        if (pdf) {
            fetch(generateFileMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = pdf?.name;
                        a.click();
                    });
                });
        }
    }

    const menuPdf = house?.pdfs.length > 0 ? <Menu className="pdfs-dropdown">
            {house?.pdfs && house?.pdfs?.map((item) => {
                return <Menu.Item key={item.id}
                                  onClick={() => downloadFile(item?.id)}
                                  className={'pdf-dropdown-item'}>
                    <div>{item.name}</div>
                </Menu.Item>
            })
            }
        </Menu> : ''

    return <Link to={getUrlWithLocal(`/floor/${house?.floor?.number}/place/${house?.number}`)}>
        <div className={'house-card'}>
            {/*<div className={'house-number'}>N {areas.houseNumber}</div>*/}
            <div className={'card-img-wrapper'}>
                <img src={resizeImage(generateImageMediaUrl(house?.mediaMain?.path))} alt=""/>
            </div>
            <div className={'card-badge'}>
                <span className={'price'}>{staticTexts?.house_card_price}</span>
                <span className={'sale'} style={{color: house?.badge?.color}}>{house?.badge?.title}</span>
            </div>
            {house?.price ? <div className={'card-price'}>{priceWithCommas(house?.price)} <DramIcon/></div>
                : <div className={'card-price'}>{staticTexts?.house_card_price_contractual}</div>}
            <div className={'areas-list'}>
                <div className={'list-item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_area_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{`${house?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup></div>
                </div>
                <div className={'list-item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_floor_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{house?.floor?.number}</div>
                </div>
                <div className={'list-item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_rooms_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{house?.roomsCount || "-"}</div>
                </div>
            </div>
            <div className={'card-btns-wrapper'} onClick={preventClick}>
                <PrimaryButton disabled={house?.badge?.id !== HOUSE_BADGES?.available?.key} title={staticTexts?.house_card_reverse_btn} cb={toggleVisibility}/>
                <Dropdown overlay={menuPdf}
                          trigger={['click']}
                          overlayClassName={'pdfs-wrapper'}
                          placement="bottomRight"
                          size={'small'}>
                    <button className={'PrimaryButton upload-btn'}><UploadIcon/></button>
                </Dropdown>
                <ReserveModal staticTexts={staticTexts} house={house} visible={visible} toggleVisibility={toggleVisibility}/>
            </div>
        </div>
    </Link>
}


const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(HouseCard);

