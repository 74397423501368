// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import assets
import "./about.scss"

// Import Components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import {GetAbout} from "../../redux/actions";
import AddressWrapper from "../../components/AddressWrapper/AddressWrapper";


class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStorage: {},
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const {staticTexts, about} = this.props

        return <div className={'AboutWrapper'}>
            <AddressWrapper addressFirst = {staticTexts?.homepage_about_button_about}/>
            <div className={'about-block'}  style={{backgroundImage: `url(${generateFileMediaUrl(about?.backgroundImage?.path)})`}}>
                <div className="CountsWrapper">
                    <div className={'counts-bg'}>
                        <h1 className={'about-main-title'}>{about?.pageName}</h1>
                    </div>
                </div>
            </div>
            <div className={'AboutMainContent'}>
                <div className={'background-block'}/>
                <div className={'LeftSide'}>
                    <div className={'text-wrapper'}>
                        <div className={'LeftSideTitle'}>{about?.blocks[0]?.title}</div>
                        <div className={'buildings-text'} dangerouslySetInnerHTML={{__html:  about?.blocks[0]?.description}}>
                        </div>
                        <div className={'aboutUs-block'}>
                            <div className={'about-description'}>
                                <span className={'number'}>{about?.workersCount}+</span>
                                <span className={'texts'}>{staticTexts?.homepage_years_count}</span>
                            </div>
                            <div className={'about-description'}>
                                <span className={'number'}>{about?.projectsCount}+</span>
                                <span className={'texts'}>{staticTexts?.homepage_success_projects}</span>
                            </div>
                            <div className={'about-description'}>
                                <span className={'number'}>{about?.commercialsCount} {staticTexts?.measurement_unit_meter}<sup>2</sup></span>
                                <span className={'texts'}>{staticTexts?.homepage_commercials_count}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'img_wrapper'}>
                        <div className={'leftImg-block'}>
                            <img className={'buildings-images'} src={generateFileMediaUrl(about?.blocks[0]?.mediaMain?.path)} alt=""/>
                        </div>
                    </div>
                </div>
                <div className={'RightSide'}>
                    <div className={'text-wrapper'}>
                        <div className={'RightSideTitle'}>{about?.blocks[1]?.title}</div>
                        <div className={'buildings-text'}>
                            <span></span>
                            <p dangerouslySetInnerHTML={{__html:  about?.blocks[1]?.description}}>
                            </p>
                        </div>
                        </div>
                    <div className={'rightImg-wrapper'}>
                        <div className={'rightImg-block'}>
                            <img className={'buildings-images'} src={generateFileMediaUrl(about?.blocks[1]?.mediaMain?.path)} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'aboutSlider',
    'about'
]);

const mapDispatchToProps = {
    GetAbout,
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
