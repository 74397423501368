import React, {useEffect, useRef} from 'react'
import './../containers/homepage/profitableOffer/ProfitableOffer.scss'
import {getPropsFromState} from "../redux/mapStateToProps";
import {connect} from "react-redux";
import {GetHouses} from "../redux/actions";
import Slider from "react-slick";
import {SliderButton} from "./ui-elements/buttons/Buttons";
import SlidersCard from "./cards/SlidersCard";



function SimilarApartmentsSlider(props) {
    const {areas,staticTexts} = props
    useEffect(() => {
        props.GetHouses()
    }, [])
    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        infinite: window.innerWidth < 700,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: window.innerWidth < 700,
        centerPadding: '20px 0 0',
        ref: slider => (sliderRef = slider),
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    return <div className={'ProfitableOffer'}>
        <div className={'apartment-title-block'}>
            <h1 className={'apartment-title'}>{staticTexts?.house_card_slider_title}</h1>
            {areas?.length &&
                <SliderButton
                    className={'desktop-arrows'}
                    cbPrev={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        sliderRef.slickPrev()
                    }}
                    cbNext={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        sliderRef.slickNext()
                    }}
                />}
        </div>
        <div className={'offer-slider-block'}>
            {<Slider{...settings}>
                {areas?.map(item =>
                    <div key={item} className={'CardWrapper'}>
                        <SlidersCard areas={item}/>
                    </div>
                )}
            </Slider>}
        </div>
    </div>

}
const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
]);

const mapDispatchToProps = {
    GetHouses,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimilarApartmentsSlider);